import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { compact, flatten, startCase } from 'lodash';
import moment from 'moment';
import { map, Observable, switchMap, withLatestFrom } from 'rxjs';
import {
  AllReportFormat,
  CollectionsReport,
  CollectionsReportFormats,
  CollectionsReportType,
  OfficeReportFilters,
  OutstandingCollectionsReportFormats,
  OutstandingCollectionsReportType,
  ReportFieldDefinition,
  ReportFieldType,
  ReportFilters,
  ReportFormats,
  ReportType,
  SystemWideReportFormats,
  SystemWideReportType,
  UserOfficeReportResponseType,
} from 'src/app/modules/reports/models/ReportType';
import { ReportService } from 'src/app/modules/reports/services/reports.service';
import { Response } from 'src/app/shared/models/response.model';
import {
  getReport,
  OfficeReportType,
  setCollectionsReport,
  setOfficeReport,
  setOutstandingCollectionsReport,
  setReport,
  setSystemWideReport,
} from './reports.slice';
import { RootState, getMetadataState } from '../stats';
import { isNil } from 'lodash/fp';
import { reportsFormatters } from 'src/app/shared/utils/reports';

interface CustomReportService {
  type: 'custom';
  service: (
    filters: ReportFilters,
    metadata?: RootState['stats']['metadata'],
  ) => Observable<Action>;
}
interface GeneralReportService {
  type: 'general';
  service: (filters: ReportFilters) => Observable<any>;
}
type ReportServiceType = CustomReportService | GeneralReportService;
type ReportServiceMap = Record<ReportType, ReportServiceType>;

@Injectable()
export class ReportEffects {
  constructor(
    private readonly action$: Actions,
    private readonly reportService: ReportService,
    private readonly store: Store,
  ) {}

  reportFieldFormatter: Record<
    ReportFieldType,
    (
      value: string,
      metadata?: RootState['stats']['metadata'],
    ) => string | undefined
  > = reportsFormatters;

  private getRowDataValues = ({
    rowData,
    reportDefinition,
    metadata,
    filters,
  }: {
    rowData: object;
    reportDefinition: AllReportFormat;
    metadata?: RootState['stats']['metadata'];
    filters?: ReportFilters | OfficeReportFilters;
  }) =>
    Object.entries(reportDefinition).map(([key, formatParams]) => {
      const columnValueRaw = (rowData as any)[key];
      const _formatParams = formatParams as ReportFieldDefinition;
      if (
        _formatParams?.isHidden ||
        (_formatParams?.isHiddenByFilter && !filters?.is_shown)
      ) {
        return { isHidden: true, value: columnValueRaw, key };
      } else if (!_formatParams?.fieldType) {
        return columnValueRaw;
      }

      return (
        this.reportFieldFormatter[_formatParams.fieldType](
          columnValueRaw,
          metadata,
        ) ?? 'NA'
      );
    });

  private createReport = <T extends object>({
    data,
    reportType,
    metadata,
    filters,
  }: {
    data: T[];
    reportType: ReportType;
    metadata?: RootState['stats']['metadata'];
    filters?: ReportFilters | OfficeReportFilters;
  }) => {
    if (!data) {
      return;
    }

    const firstObject = data[0];
    if (!firstObject) {
      return {
        headers: [],
        values: [],
      };
    }

    const reportDefinition = ReportFormats[reportType] as ReportFieldDefinition;
    const totalRow = data.find((it: any) => it.is_total);

    if (!reportDefinition) {
      return {
        headers: Object.keys(data[0]).map(startCase),
        values: data.filter((it: any) => !it.is_total).map(Object.values),
        total: totalRow ? Object.values(totalRow) : null,
      };
    }

    return {
      headers: Object.entries(reportDefinition)
        .filter(([_, formatParams]) => {          
          return !(
            formatParams?.isHidden ||
            (formatParams?.isHiddenByFilter && !filters?.is_shown)
          );
        })
        .map(([key, formatParams]) => {
          const _formatParams = formatParams;
          if (_formatParams?.title) {
            return _formatParams.title;
          }
          return startCase(key);
        }),
      values: data
        .filter((it: any) => !it.is_total)
        .map((it) =>
          this.getRowDataValues({
            rowData: it,
            reportDefinition,
            metadata,
            filters,
          }),
        ),
      total: totalRow
        ? this.getRowDataValues({
            rowData: totalRow,
            reportDefinition,
            filters,
          })
        : null,
    };
  };

  private createSystemWideReport = <T extends object>(
    data: T[],
    reportType: SystemWideReportType,
    filters: ReportFilters,
  ) => {
    const firstObject = data[0];
    if (!firstObject) {
      return;
    }
    const reportDefinition = SystemWideReportFormats[reportType];
    const totalRow = data.find((it: any) => it.is_total);

    if (!reportDefinition) {
      return {
        headers: Object.keys(data[0]).map(startCase),
        values: data.filter((it: any) => !it.is_total).map(Object.values),
        total: totalRow ? Object.values(totalRow) : null,
      };
    }
    return {
      headers: Object.entries(reportDefinition)
        .filter(([_, formatParams]) => {
          return !(
            formatParams?.isHidden ||
            (formatParams?.isHiddenByFilter && !filters?.is_shown)
          );        })
        .map(([key, formatParams]) => {
          const _formatParams = formatParams as ReportFieldDefinition;
          if (_formatParams?.title) {
            return _formatParams.title;
          }
          return startCase(key);
        }),
      values: data
        .filter((it: any) => !it.is_total)
        .map((it) =>
          this.getRowDataValues({ rowData: it, reportDefinition, filters }),
        ),
      total: totalRow
        ? this.getRowDataValues({
            rowData: totalRow,
            reportDefinition,
            filters,
          })
        : null,
    };
  };

  private createCollectionsReport = <T extends object>(
    data: T[],
    reportType: CollectionsReportType,
    totalRow: object,
  ) => {
    const firstObject = data[0];
    if (!firstObject) {
      return {
        headers: [],
        values: [],
      };
    }
    const reportDefinition = CollectionsReportFormats[reportType];

    if (!reportDefinition) {
      return {
        headers: Object.keys(data[0]).map(startCase),
        values: data.filter((it: any) => !it.is_total).map(Object.values),
        total: totalRow ? Object.values(totalRow) : null,
      };
    }
    return {
      headers: Object.entries(reportDefinition)
        .filter(([_, formatParams]) => {
          return !formatParams?.isHidden;
        })
        .map(([key, formatParams]) => {
          const _formatParams = formatParams as ReportFieldDefinition;
          if (_formatParams?.title) {
            return _formatParams.title;
          }
          return startCase(key);
        }),
      values: data
        .filter((it: any) => !it.is_total)
        .map((it) => this.getRowDataValues({ rowData: it, reportDefinition })),
      total: totalRow
        ? this.getRowDataValues({ rowData: totalRow, reportDefinition })
        : null,
    };
  };

  private createOutstandingCollectionsReport = <T extends object>(
    data: T[],
    reportType: OutstandingCollectionsReportType,
    totalRow: object,
  ) => {
    const firstObject = data[0];
    if (!firstObject) {
      return {
        headers: [],
        values: [],
      };
    }
    const reportDefinition = OutstandingCollectionsReportFormats[reportType];

    if (!reportDefinition) {
      return {
        headers: Object.keys(data[0]).map(startCase),
        values: data.map(Object.values),
        total: Object.values(totalRow),
      };
    }

    return {
      headers: Object.entries(reportDefinition)
        .filter(([_, formatParams]) => {
          return !formatParams?.isHidden;
        })
        .map(([key, formatParams]) => {
          const _formatParams = formatParams as ReportFieldDefinition;
          if (_formatParams?.title) {
            return _formatParams.title;
          }
          return startCase(key);
        }),
      values: data.map((it) =>
        this.getRowDataValues({ rowData: it, reportDefinition }),
      ),
      total: this.getRowDataValues({ rowData: totalRow, reportDefinition }),
    };
  };

  private getGeneralReportResponse = <T extends object>(
    responseObservable: Observable<Response<T[]>>,
    reportType: ReportType,
    metadataState?: RootState['stats']['metadata'],
  ) => {
    return responseObservable.pipe(
      map((response) => {
        return setReport({
          report: this.createReport({
            data: response.data,
            reportType,
            metadata: metadataState,
          }),
        });
      }),
    );
  };

  private getCollectionsReport = (filters: ReportFilters) => {
    return this.reportService.getCollectionsReport(filters).pipe(
      map((response) => {
        return setCollectionsReport({
          collectionsReport: {
            data: response.data
              .filter((it: CollectionsReport) => it.grand_total === undefined)
              .map((it: CollectionsReport) => ({
                officeName: it.office_name,
                totalCollections: it.total_collections_received,
                moneyDowns: this.createCollectionsReport(
                  it.money_down ?? [],
                  CollectionsReportType.MoneyDown,

                  {
                    fee_collected: it.total_money_down_fee_collected,
                  },
                )!,
                otherFees: this.createCollectionsReport(
                  it.other_fee ?? [],
                  CollectionsReportType.Other,
                  {
                    fee_amount: it.total_other_fee_amount,
                  },
                )!,
                placements: this.createCollectionsReport(
                  it.placements ?? [],
                  CollectionsReportType.Placement,
                  {
                    invoice_fee: it.total_placement_invoice_fee,
                    fee_collected: it.total_placement_fee_collected,
                  },
                )!,
                fallouts: this.createCollectionsReport(
                  it.fallouts ?? [],
                  CollectionsReportType.Fallouts,
                  {
                    invoice_fee: it.total_fallout_invoice_fee,
                    fee_collected: it.total_fallout_fee_collected,
                  },
                )!,
              })),
            grandTotal: response.data.find((it) => it.grand_total !== undefined)
              ?.grand_total,
          },
        });
      }),
    );
  };

  private getOutstandingCollectionsReport = (filters: ReportFilters) => {
    return this.reportService.getOutstandingCollectionsReport(filters).pipe(
      map((response) => {
        return setOutstandingCollectionsReport({
          outstandingCollectionsReport: response.data.map((it) => ({
            officeName: it.office_name,
            placements: this.createOutstandingCollectionsReport(
              it.placements ?? [],
              OutstandingCollectionsReportType.Placement,
              {
                fee_collected: it.total_fee_collected,
                invoice_fee: it.total_invoice_fee,
                outstanding: it.total_outstanding,
              },
            )!,
          })),
        });
      }),
    );
  };

  private getSystemWideReport = (filters: ReportFilters) => {
    return this.reportService.getSystemWideReport(filters).pipe(
      map((response) => {
        return setSystemWideReport({
          systemWideReport: {
            currentYear: this.createSystemWideReport(
              response.data[0],
              SystemWideReportType.Yearly,
              filters,
            )!,
            previousYear: this.createSystemWideReport(
              response.data[1],
              SystemWideReportType.Yearly,
              filters,
            )!,
            allItems: this.createSystemWideReport(
              response.data[2],
              SystemWideReportType.AllItems,
              filters,
            )!,
          },
        });
      }),
    );
  };

  private actionToFunctionMap: Partial<ReportServiceMap> = {
    [ReportType.TopPlacementReport]: {
      type: 'general',
      service: this.reportService.getPlacementReport,
    },
    [ReportType.TopMoneyDownSearchReport]: {
      type: 'general',
      service: this.reportService.getMoneyDownSearchReport,
    },
    [ReportType.SystemWideReport]: {
      type: 'custom',
      service: this.getSystemWideReport,
    },
    [ReportType.CollectionsReport]: {
      type: 'custom',
      service: this.getCollectionsReport,
    },
    [ReportType.OutstandingCollectionsReport]: {
      type: 'custom',
      service: this.getOutstandingCollectionsReport,
    },
    [ReportType.TopRecruiterReport]: {
      type: 'general',
      service: this.reportService.getTopRecruitersReport,
    },
    [ReportType.TopIndividualReport]: {
      type: 'general',
      service: this.reportService.getTopIndividualReport,
    },
    [ReportType.TopOwnerReport]: {
      type: 'general',
      service: this.reportService.getTopOwnerReport,
    },
    [ReportType.TopRecruiterExchangeReport]: {
      type: 'general',
      service: this.reportService.getTopRecruiterExchangeReport,
    },
    [ReportType.TopOwnerExchangeReport]: {
      type: 'general',
      service: this.reportService.getTopOwnerExchangeReport,
    },
    [ReportType.TopOfficeReport]: {
      type: 'general',
      service: this.reportService.getTopOfficeReport,
    },
    [ReportType.TopOfficeExchangeReport]: {
      type: 'general',
      service: this.reportService.getTopOfficeExchangeReport,
    },
    [ReportType.AgingReport]: {
      type: 'general',
      service: this.reportService.getAgingReport,
    },
    [ReportType.TopPercentileByIndustry]: {
      type: 'general',
      service: this.reportService.getTopPercentileByIndustry,
    },
    [ReportType.TopPercentileByJobFunction]: {
      type: 'general',
      service: this.reportService.getTopPercentileByJobFunction,
    },
    [ReportType.FalloutReport]: {
      type: 'general',
      service: this.reportService.getFalloutReport,
    },
  };

  private getOfficeReport = (filters: OfficeReportFilters) => {
    return this.reportService.getOfficeReport(filters).pipe(
      map((response) => {
        const officeReportData: OfficeReportType = {};

        response.data?.forEach(
          (userReportData: UserOfficeReportResponseType) => {
            const reportData = this.createReport({
              data: userReportData.data,
              reportType: ReportType.OfficeReport,
              filters: filters,
            });

            if (reportData) {
              officeReportData[`${userReportData.name}`] = reportData;
            }
          },
        );

        return setOfficeReport({ officeReport: officeReportData });
      }),
    );
  };

  getReport$ = createEffect(() =>
    this.action$.pipe(
      ofType(getReport),
      withLatestFrom(this.store.select(getMetadataState)),
      switchMap(([{ payload }, metadataState]) => {
        const { reportType, filters } = payload;
        if (reportType === ReportType.OfficeReport) {
          return this.getOfficeReport(filters);
        }
        if (reportType === ReportType.CollectionsReport) {
          return this.getCollectionsReport(filters);
        }
        if (reportType === ReportType.OutstandingCollectionsReport) {
          return this.getOutstandingCollectionsReport(filters);
        }
        if (reportType === ReportType.SystemWideReport) {
          return this.getSystemWideReport(filters);
        }
        const serviceDetails = this.actionToFunctionMap[reportType]!;

        if (serviceDetails.type === 'custom') {
          return serviceDetails.service(filters, metadataState);
        }
        if (serviceDetails.type === 'general')
          return this.getGeneralReportResponse(
            serviceDetails.service(filters),
            reportType,
            metadataState,
          );
        throw new Error('Invalid report type');
      }),
    ),
  );
}
