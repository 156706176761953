import { activityFeatureKey, FeatureState as ActivityState } from '../activity';
import reportsSlice, { ReportsState } from '../reports/reports.slice';
import { FeatureState, statsFeatureKey } from './reducers';

export * from './reducers';
export * from './actions';
export * from './selectors';

export interface RootState {
    [statsFeatureKey]: FeatureState;
    [reportsSlice.name]: ReportsState;
    [activityFeatureKey]: ActivityState;
}
