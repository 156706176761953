import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import {
    BehaviorSubject,
    map,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    tap,
} from 'rxjs';

import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../../modules/authentication/models/login-form.model';
import { Response } from '../models/response.model';
import { User, UserDetails } from '../models/user.model';
import { ConfirmPasswordPayload } from '../../modules/reset-password/models/reset.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private cachedUserDetails: Observable<UserDetails> | null = null;
    user = new BehaviorSubject<User | null>(null);
    userDetails = this.getUserDetails(this.user);

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private router: Router,
    ) {}

    clearCachedData() {
        this.cachedUserDetails = null;
        this.userDetails = this.getUserDetails(this.user);
    }

    getUserDetails(user: Subject<User | null>) {
        return user.pipe(
            switchMap(() => {
                if (!this.getCurrentUser()) {
                    return of({} as UserDetails);
                }

                if (!this.cachedUserDetails) {
                    this.cachedUserDetails = this.http
                        .get<Response<UserDetails>>(
                            `${environment.baseUrl}/metadata/user-details`,
                        )
                        .pipe(
                            map((it) => it.data),
                            shareReplay(1),
                        );
                }
                return this.cachedUserDetails;
            }),
        );
    }

    isLoggedin(): boolean {
        return !!this.getCurrentUser();
    }

    getCurrentUser() {
        const retrievedUser = this.cookieService.getCookie('userData');
        const userData: User = retrievedUser ? JSON.parse(retrievedUser) : null;
        return userData;
    }

    autoLogin() {
        this.user.next(this.getCurrentUser());
    }

    login(payload: LoginForm) {
        const { username, password } = payload;

        return this.http
            .post<{ account_id: number }>(`${environment.authUrl}/login`, {
                username,
                password,
            })
            .pipe(
                tap(({ account_id }) => {
                    console.log('account_id', account_id);
                    const bearer = this.cookieService.getCookie('bearer');
                    const jwtDecoded = this.getDecodedJwtToken(bearer);

                    const { sub, exp, iat } = jwtDecoded;

                    const user: User = {
                        sub,
                        exp,
                        iat,
                        accountId: account_id,
                    };
                    let userData = JSON.stringify(user);

                    let domain = window.location.hostname;

                    if (domain !== 'localhost') {
                        domain = domain.substring(domain.indexOf('.'));
                    }

                    // set data
                    document.cookie =
                        'userData=' +
                        encodeURIComponent(userData) +
                        `; domain=${domain}; path=/`;

                    this.user.next(this.getCurrentUser());

                    // TODO: refresh token
                    // const refreshToken = this.cookieService.getCookie('refresh_token');
                    // localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
                }),
            );
    }

    getDecodedJwtToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    sendResetLink(email: string): Observable<any> {
        return this.http.post(`${environment.authUrl}/reset`, {
            username: email,
        });
    }

    confirmPassword(resetDataPayload: ConfirmPasswordPayload): Observable<any> {
        return this.http.post(
            `${environment.authUrl}/reset/password`,
            resetDataPayload,
        );
    }

    private handleError(errorRes: HttpErrorResponse) {
        let errorMessage = 'An unknown error occurred!';
        if (errorRes.status == 401) {
            errorMessage = 'Unknown user';
        }
        return of(errorMessage);
    }

    handleLogout(cookie: string) {
        this.cookieService.deleteCookie(cookie);
        this.cookieService.deleteCookie('userData');

        this.user.next(null);
        this.clearCachedData();

        window.location.href = environment.applicationAuthUrl + '/login';
    }
}
