import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { OtherFee } from '../../../modules/stats/models/other-fee.model';

import * as fromFeature from '../reducers';
import * as fromOtherFee from '../reducers/other-fee.reducer';

export const getOtherFeeState = createSelector(
    fromFeature.getFeatureState,
    (state: fromFeature.FeatureState) => state.otherFee,
);

export const getOtherFeeEntities = createSelector(
    getOtherFeeState,
    fromOtherFee.selectEntities,
);

export const getAllOtherFees = createSelector(
    getOtherFeeEntities,
    (entities) => {
        return (Object.values(entities) as OtherFee[]).sort((a, b) => {
            return (
                moment(b.created_datetime).unix() -
                moment(a.created_datetime).unix()
            );
        });
    },
);
