import { Component } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './shared/services/auth.service';
import { LoadingService } from './shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loading$ = this.loader.loading$;

  isAuthenticated: boolean = false;
  authSubscription!: Subscription;

  constructor(
    public router: Router,
    public loader: LoadingService,
    private authService: AuthService,
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loader.startLoading();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loader.stopLoading();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  showHeader(): Boolean {
    const noHeaderRoutes = ['login', 'main'];
    const firstPath = this.router.url?.split('/')?.[1] || '';

    if (noHeaderRoutes.includes(firstPath)) {
      return false;
    }

    return true;
  }

  ngOnInit() {
    this.authService.autoLogin();
    this.authSubscription = this.authService.user.subscribe((user) => {
      this.isAuthenticated = !!user;
    });
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }
}
