import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
    General,
    GeneralFilters,
} from '../../../modules/stats/models/general.model';

/**
 *
 * Load generals */
export const loadGenerals = createAction(
    '[Stats] Load Generals',
    props<{ filters: GeneralFilters }>(),
);

export const loadGeneralsSuccess = createAction(
    '[Stats] Load Generals Success',
    props<{ generals: General[] }>(),
);

export const loadGeneralsFailure = createAction(
    '[Stats] Load Generals Failure',
    props<{ error: any }>(),
);

/**
 *
 * Upsert general */
export const upsertGeneral = createAction(
    '[Stats] Upsert General',
    props<{ general: General }>(),
);

export const upsertGeneralSuccess = createAction(
    '[Stats] Upsert General Success',
    props<{ general: General }>(),
);

export const upsertGeneralFailure = createAction(
    '[Stats] Upsert Genera Failurel',
    props<{ error: any }>(),
);

/**
 *
 * Delete general */
export const deleteGeneral = createAction(
    '[Stats] Delete General',
    props<{ id: number }>(),
);

export const deleteGeneralSuccess = createAction(
    '[Stats] Delete General Success',
    props<{ id: number }>(),
);

export const deleteGeneralFailure = createAction(
    '[Stats] Delete Genera Failurel',
    props<{ error: any }>(),
);

export const editGeneralObject = createAction(
    '[Stats] Edit General Object',
    props<{ id: number }>(),
);
