<div class="header-container">
  <nav class="navbar navbar-expand-lg navbar-light">
    <a
      class="logo"
      routerLink="/"
    >
      <img
        src="assets/images/logo_fpc_main_dark.png"
        alt=""
      />
    </a>
    <ng-container *ngIf="isAuthenticated">
      <div class="container-fluid">
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav">
            <ng-container *ngIf="showStatsLinks">
              <li>
                <a
                  routerLinkActive="active"
                  routerLink="/stats"
                  class="nav-link"
                >
                  Input Stats
                </a>
              </li>
              <li>
                <a
                  routerLinkActive="active"
                  routerLink="/activity"
                  class="nav-link position-relative"
                >
                  <span>Pending Activity</span>
                  <div
                    *ngIf="hasPendingActivities"
                    class="notification-dot"
                  ></div>
                </a>
              </li>
              <li>
                <a
                  routerLinkActive="active"
                  routerLink="/reports"
                  class="nav-link"
                >
                  Reports
                </a>
              </li>

              <li *ngIf="showUserManagementScreens">
                <a
                  routerLinkActive="active"
                  routerLink="/user-management"
                  class="nav-link"
                >
                  User Management
                </a>
              </li>

              <li *ngIf="showOfficeManagementScreens">
                <a
                  routerLinkActive="active"
                  routerLink="/office-management"
                  class="nav-link"
                >
                  Office Management
                </a>
              </li>
            </ng-container>            
          </ul>
        </div>
      </div>

      <div
        role="button"
        class="header-profile-container"
        ngbDropdown
      >
        <div
          class="d-flex"
          ngbDropdownToggle
        >
          <div class="me-2">
            <div class="name">{{ fullName }}</div>
            <div class="subtitle">{{ subtitle }}</div>
          </div>

          <img
            class="profile-img"
            src="/assets/images/person.svg"
            width="40"
            height="40"
            alt=""
          />

          <div
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
          >
            <a
              class="ms-3 nav-link logout"
              (click)="onLogout()"
            >
              <img
                src="/assets/svgs/logout.svg"
                class="bi me-2"
              />
              Logout
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </nav>
</div>
