import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Placement } from 'src/app/shared/models/placement.model';
import { Response } from 'src/app/shared/models/response.model';
import { environment } from 'src/environments/environment';
import { MoneyDownSearch } from '../../stats/models/money-down-search.model';
import { ReportResponse } from '../models/ReportResponse';
import {
  AgingReportItem,
  CollectionsReport,
  OfficeReportFilters,
  OutstandingCollectionsReport,
  ReportFilters,
  ReportType,
  SystemWideReport,
  TopExchangeItem,
  TopOfficeExchangeItem,
  TopOfficeItem,
  TopPercentileByJobFunctionReport,
  TopRecruiterItem,
  UserOfficeReportResponseType,
  YTDCollectionReport,
} from '../models/ReportType';
import { identity, isNil, omitBy, pickBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly baseUrl = `${environment.baseUrl}/reporting`;
  constructor(private http: HttpClient) {}

  readonly routeMap = {
    [ReportType.SystemWideReport]: 'systemwide',
    [ReportType.OfficeReport]: 'office',
    [ReportType.TopPlacementReport]: 'placement',
    [ReportType.TopMoneyDownSearchReport]: 'money-down',
    [ReportType.TopRecruiterReport]: 'toprecruiter',
    [ReportType.TopIndividualReport]: 'topindividual',
    [ReportType.TopOwnerReport]: 'topowner',
    [ReportType.TopOfficeReport]: 'topoffice',
    [ReportType.TopRecruiterExchangeReport]: 'toprecruiter-exchange',
    [ReportType.TopOwnerExchangeReport]: 'topowner-exchange',
    [ReportType.TopOfficeExchangeReport]: 'topoffice-exchange',
    [ReportType.AgingReport]: 'aging',
    [ReportType.TopPercentileByJobFunction]: 'top-by-function',
    [ReportType.TopPercentileByIndustry]: 'top-by-industry',
    [ReportType.CollectionsReport]: 'collection',
    [ReportType.OutstandingCollectionsReport]: 'outstanding',
    [ReportType.FalloutReport]: 'fallout',
  };

  downloadReport = (reportType: ReportType, filters: ReportFilters) => {
    return this.http.get(`${this.baseUrl}/${this.routeMap[reportType]}/excel`, {
      params: new HttpParams({ fromObject: filters as any }),
      responseType: 'blob',
    });
  };

  getPlacementReport = (filters: ReportFilters) => {
    return this.http.get<ReportResponse<Placement, 'job_placement_list'>>(
      `${this.baseUrl}/placement`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getMoneyDownSearchReport = (filters: ReportFilters) => {
    return this.http.get<ReportResponse<MoneyDownSearch, 'money_down_list'>>(
      `${this.baseUrl}/money-down`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getTopRecruitersReport = (filters: ReportFilters) => {
    return this.http.get<Response<TopRecruiterItem[]>>(
      `${this.baseUrl}/toprecruiter`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getTopIndividualReport = (filters: ReportFilters) => {
    return this.http.get<Response<TopRecruiterItem[]>>(
      `${this.baseUrl}/topindividual`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getTopOwnerReport = (filters: ReportFilters) => {
    return this.http.get<Response<TopRecruiterItem[]>>(
      `${this.baseUrl}/topowner`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getTopOfficeReport = (filters: ReportFilters) => {
    return this.http.get<Response<TopOfficeItem[]>>(
      `${this.baseUrl}/topoffice`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getTopRecruiterExchangeReport = (filters: ReportFilters) => {
    return this.http.get<Response<TopExchangeItem[]>>(
      `${this.baseUrl}/toprecruiter-exchange`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getTopOwnerExchangeReport = (filters: ReportFilters) => {
    return this.http.get<Response<TopExchangeItem[]>>(
      `${this.baseUrl}/topowner-exchange`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getTopOfficeExchangeReport = (filters: ReportFilters) => {
    return this.http.get<Response<TopOfficeExchangeItem[]>>(
      `${this.baseUrl}/topoffice-exchange`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getSystemWideReport = (filters: ReportFilters) => {
    return this.http.get<Response<SystemWideReport>>(
      `${this.baseUrl}/systemwide`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getOfficeReport = (filters: OfficeReportFilters) => {
    return this.http.get<Response<UserOfficeReportResponseType[]>>(
      `${this.baseUrl}/office`,
      {
        params: omitBy(filters, (value) => isNil(value) || value === ''),
      },
    );
  };

  getAgingReport = (filters: ReportFilters) => {
    return this.http.get<Response<AgingReportItem[]>>(`${this.baseUrl}/aging`, {
      params: new HttpParams({ fromObject: filters as any }),
    });
  };

  getTopPercentileByJobFunction = (filters: ReportFilters) => {
    return this.http.get<Response<TopPercentileByJobFunctionReport[]>>(
      `${this.baseUrl}/top-by-function`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getTopPercentileByIndustry = (filters: ReportFilters) => {
    return this.http.get<Response<TopPercentileByJobFunctionReport[]>>(
      `${this.baseUrl}/top-by-industry`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getYTDCollection = (filters: ReportFilters) => {
    return this.http.get<Response<YTDCollectionReport[]>>(
      `${this.baseUrl}/ytd-collection`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getCollectionsReport = (filters: ReportFilters) => {
    return this.http.get<Response<CollectionsReport[]>>(
      `${this.baseUrl}/collection`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getOutstandingCollectionsReport = (filters: ReportFilters) => {
    return this.http.get<Response<OutstandingCollectionsReport[]>>(
      `${this.baseUrl}/outstanding`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };

  getFalloutReport = (filters: ReportFilters) => {
    return this.http.get<Response<YTDCollectionReport[]>>(
      `${this.baseUrl}/fallout`,
      {
        params: new HttpParams({ fromObject: filters as any }),
      },
    );
  };
}
