import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PlacementActivityDetails } from '../../../shared/models/placement.model';
import * as ActivityActions from '../actions/activity.actions';

export const activitiesFeatureKey = 'activities';

export interface State extends EntityState<PlacementActivityDetails> {
    loaded: boolean;
    error?: string;
}

export const adapter: EntityAdapter<PlacementActivityDetails> =
    createEntityAdapter<PlacementActivityDetails>();

export const initialState: State = adapter.getInitialState({
    loaded: false,
});

export const reducer = createReducer(
    initialState,
    on(ActivityActions.loadActivitiesSuccess, (state, action) =>
        adapter.setAll(action.activities, {
            ...state,
            loaded: true,
        }),
    ),
    /** Error handling */
    on(ActivityActions.loadActivitiesFailure, (state, action) => {
        const { status, statusText } = action.error;

        return { ...state, error: `${status}: ${statusText}` };
    }),
    on(
        ActivityActions.approveExchangeRequestSuccess,
        ActivityActions.declineExchangeRequestSuccess,
        (state, action) => {
            const { placementActivity } = action;
            return adapter.removeOne(placementActivity.id.toString(), state);
        },
    ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
    adapter.getSelectors();
