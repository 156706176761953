import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Response } from 'src/app/shared/models/response.model';
import { environment } from 'src/environments/environment';
import type {
    CompanySize,
    Industry,
    JobFunction,
    JobFunctionSubcategory,
    Metadata,
    Office,
    Recruiter,
    UserRole,
} from '../models/metadata.model';

enum MetadataEndpoint {
    JOB_FUNCTION = 'jobfunction',
    JOB_FUNCTION_SUB_CATEGORY = 'jobfunction/subcategory',
    INDUSTRY = 'industry',
    COMPANY_SIZE = 'company-size',
    RECRUITER = 'recruiter',
    OFFICES = 'office',
    APP_USER_TYPE = 'app-user-type',
}

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    constructor(private http: HttpClient) {}

    private getEndpoint(endpoint: MetadataEndpoint) {
        return `${environment.baseUrl}/metadata/${endpoint}`;
    }

    private getMetadata<T extends Metadata[]>(
        endpoint: MetadataEndpoint,
        options: { params?: HttpParams } = {},
    ): Observable<T> {
        return this.http
            .get<Response<T>>(this.getEndpoint(endpoint), options)
            .pipe(map((response) => response.data));
    }

    getJobFunctions(): Observable<JobFunction[]> {
        return this.getMetadata(MetadataEndpoint.JOB_FUNCTION);
    }

    getSubCategories(
        jobFunctionId: number,
    ): Observable<JobFunctionSubcategory[]> {
        const params = new HttpParams().set('job_function_id', jobFunctionId);
        return this.getMetadata(MetadataEndpoint.JOB_FUNCTION_SUB_CATEGORY, {
            params,
        });
    }

    getIndustries(): Observable<Industry[]> {
        return this.getMetadata(MetadataEndpoint.INDUSTRY);
    }

    getCompanySizes(): Observable<CompanySize[]> {
        return this.getMetadata(MetadataEndpoint.COMPANY_SIZE);
    }

    getRecruiters(): Observable<Recruiter[]> {
        return this.http
            .get<Response<Recruiter[]>>(
                this.getEndpoint(MetadataEndpoint.RECRUITER),
            )
            .pipe(map((response) => response.data));
    }

    getOffices(): Observable<Office[]> {
        return this.http
            .get<Response<Office[]>>(this.getEndpoint(MetadataEndpoint.OFFICES))
            .pipe(map((response) => response.data));
    }

    getUserRoles(includeDisabled: boolean): Observable<UserRole[]> {
        const params = new HttpParams().set(
            'include_disabled',
            includeDisabled,
        );

        return this.http
            .get<Response<UserRole[]>>(
                this.getEndpoint(MetadataEndpoint.APP_USER_TYPE),
                {
                    params,
                },
            )
            .pipe(map((response) => response.data));
    }
}
