import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  OfficeReportFilters,
  ReportFilters,
  ReportType,
  SystemWideReport,
  UserOfficeReportResponseType,
  YTDCollectionReport,
} from 'src/app/modules/reports/models/ReportType';

export interface ReportHeadersAndValues {
  headers: string[];
  values: string[][];
}

export type OfficeReportType = {
  [key: string]: ReportHeadersAndValues;
};

export interface ReportsState {
  loading: boolean;
  officeReport?: OfficeReportType;
  report?: ReportHeadersAndValues;
  ytdCollectionReport?: {
    officeName: string;
    moneyDowns: ReportHeadersAndValues;
    placements: ReportHeadersAndValues;
    otherFees: ReportHeadersAndValues;
  }[];
  collectionsReport?: {
    data: {
      officeName: string;
      placements: ReportHeadersAndValues;
      moneyDowns: ReportHeadersAndValues;
      otherFees: ReportHeadersAndValues;
      fallouts: ReportHeadersAndValues;
      totalCollections: number;
    }[];
    grandTotal?: number;
  };
  outstandingCollectionsReport?: {
    officeName: string;
    placements: ReportHeadersAndValues;
  }[];
  systemWideReport?: {
    currentYear: ReportHeadersAndValues;
    previousYear: ReportHeadersAndValues;
    allItems: ReportHeadersAndValues;
  };
}

const initialState: ReportsState = { loading: false };

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getReport: (
      state,
      _: PayloadAction<
        | {
            reportType: Exclude<ReportType, ReportType.OfficeReport>;
            filters: ReportFilters;
          }
        | {
            reportType: ReportType.OfficeReport;
            filters: OfficeReportFilters;
          }
      >,
    ) => {
      return {
        ...state,
        loading: true,
      };
    },
    setReport: (state, action: PayloadAction<Pick<ReportsState, 'report'>>) => {
      return {
        ...state,
        loading: false,
        report: action.payload.report,
      };
    },
    setOfficeReport: (
      state,
      action: PayloadAction<Pick<ReportsState, 'officeReport'>>,
    ) => {
      return {
        ...state,
        loading: false,
        officeReport: action.payload.officeReport,
      };
    },
    setYTDReport: (
      initialState,
      action: PayloadAction<Pick<ReportsState, 'ytdCollectionReport'>>,
    ) => {
      return {
        ...initialState,
        loading: false,
        ytdCollectionReport: action.payload.ytdCollectionReport,
      };
    },
    setCollectionsReport: (
      initialState,
      action: PayloadAction<Pick<ReportsState, 'collectionsReport'>>,
    ) => {
      return {
        ...initialState,
        loading: false,
        collectionsReport: action.payload.collectionsReport,
      };
    },
    setOutstandingCollectionsReport: (
      initialState,
      action: PayloadAction<Pick<ReportsState, 'outstandingCollectionsReport'>>,
    ) => {
      return {
        ...initialState,
        loading: false,
        outstandingCollectionsReport:
          action.payload.outstandingCollectionsReport,
      };
    },
    setSystemWideReport: (
      initialState,
      action: PayloadAction<Pick<ReportsState, 'systemWideReport'>>,
    ) => {
      return {
        ...initialState,
        loading: false,
        systemWideReport: action.payload.systemWideReport,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getReport,
  setReport,
  setOfficeReport,
  setSystemWideReport,
  setYTDReport,
  setCollectionsReport,
  setOutstandingCollectionsReport,
} = reportsSlice.actions;

export default reportsSlice;
