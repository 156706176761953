import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, map, Observable, throwError } from 'rxjs';

import { Response } from 'src/app/shared/models/response.model';
import { environment } from 'src/environments/environment';
import {
  MoneyDownPaymentHistory,
  MoneyDownSearch,
} from '../../modules/stats/models/money-down-search.model';
import {
  Placement,
  PlacementActivity,
  PlacementFilters,
  PlacementHistory,
  PlacementRevenue,
  PlacementRevenueHistory,
} from '../models/placement.model';

@Injectable({
  providedIn: 'root',
})
export class PlacementService {
  constructor(private http: HttpClient) {}

  getPlacements(filters: Partial<PlacementFilters>): Observable<Placement[]> {
    let params = new HttpParams();

    Object.entries(filters)
      .filter(
        ([key, value]) =>
          value !== undefined &&
          value !== 'null' &&
          value !== null &&
          key !== 'selectedMonth',
      )
      .forEach(([key, value]) => {
        params = params.set(key, value);
      });

    if (!filters.outstanding) {
      let selectedMonth = moment(filters.selectedMonth, 'YYYY-MM');
      params = params.set('year', selectedMonth.year());
      params = params.set('month', selectedMonth.month() + 1);
    }

    return this.http
      .get<Response<Placement[]>>(`${environment.baseUrl}/placement/list`, {
        params,
      })
      .pipe(
        map((response) => {
          const { data } = response;

          return data;
        }),
        catchError((error) => throwError(() => error)),
      );
  }

  createPlacement(placement: Placement): Observable<Placement> {
    // TODO Remove this when IDP has been implemented locally
    const payload = {
      ...placement,
      created_by: 1,
    };
    return this.http
      .post<Response<Placement>>(`${environment.baseUrl}/placement`, payload)
      .pipe(
        map((response) => {
          const { data } = response;
          return data;
        }),
        catchError((error) => throwError(() => error)),
      );
  }

  updatePlacement(payload: Partial<Placement>): Observable<Placement> {
    return this.http
      .patch<Response<Placement>>(`${environment.baseUrl}/placement`, payload)
      .pipe(
        map((response) => {
          const { data } = response;

          return data;
        }),
        catchError((error) => throwError(() => error)),
      );
  }

  deletePlacement(id: number): Observable<Placement> {
    const params = new HttpParams().set('id', id);

    return this.http
      .delete<Placement>(`${environment.baseUrl}/placement`, {
        params,
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  markPlacementAsCollected(
    id: number,
    data: { fee_amount: number; note: string; placement_date: string },
  ): Observable<Response<PlacementRevenue>> {
    const payload = {
      ...data,
      job_placement_id: id,
    };
    return this.http.post<Response<PlacementRevenue>>(
      `${environment.baseUrl}/placement-collect`,
      payload,
    );
  }

  editPlacementCollect(data: {
    id: number;
    fee_amount: number;
    note?: string;
  }): Observable<Response<PlacementRevenue>> {
    return this.http.put<Response<PlacementRevenue>>(
      `${environment.baseUrl}/placement-collect`,
      data,
    );
  }

  markPlacementAsFallout(
    id: number,
    data: { fee_amount: number; note: string; placement_date: string },
  ): Observable<Response<PlacementRevenue>> {
    const payload = {
      ...data,
      job_placement_id: id,
    };
    return this.http.post<Response<PlacementRevenue>>(
      `${environment.baseUrl}/placement-fallout`,
      payload,
    );
  }

  editPlacementFallout(data: {
    id: number;
    fee_amount: number;
    note?: string;
  }): Observable<Response<PlacementRevenue>> {
    return this.http.put<Response<PlacementRevenue>>(
      `${environment.baseUrl}/placement-fallout`,
      data,
    );
  }

  getPlacementCollects(
    ids: number[],
  ): Observable<Response<PlacementRevenue[]>> {
    const params = new HttpParams().set('ids', ids.join(','));
    return this.http.get<Response<PlacementRevenue[]>>(
      `${environment.baseUrl}/placement-collect/list`,
      {
        params,
      },
    );
  }

  getPlacementFallouts(
    ids: number[],
  ): Observable<Response<PlacementRevenue[]>> {
    const params = new HttpParams().set('ids', ids.join(','));
    return this.http.get<Response<PlacementRevenue[]>>(
      `${environment.baseUrl}/placement-fallout/list`,
      {
        params,
      },
    );
  }

  getPlacementHistory(
    placementId: number,
  ): Observable<Response<PlacementHistory[]>> {
    return this.http.get<Response<PlacementHistory[]>>(
      `${environment.baseUrl}/placement/history`,
      {
        params: new HttpParams().set('job_placement_id', placementId),
      },
    );
  }

  getMoneyDownPaymentHistory(
    moneyDownSearchId: number,
  ): Observable<Response<MoneyDownPaymentHistory[]>> {
    return this.http.get<Response<MoneyDownPaymentHistory[]>>(
      `${environment.baseUrl}/money-down-payment/history`,
      {
        params: new HttpParams().set('money_down_search_id', moneyDownSearchId),
      },
    );
  }

  getPlacementActivity(filters: Partial<PlacementFilters>) {
    return this.http.get<Response<PlacementActivity[]>>(
      `${environment.baseUrl}/placement/activity`,
    );
  }

  approveExchangeRequest(placementId: number) {
    return this.http.put<Response<PlacementActivity>>(
      `${environment.baseUrl}/placement/approve`,
      { id: placementId },
    );
  }

  declineExchangeRequest(placementId: number) {
    return this.http.put<Response<PlacementActivity>>(
      `${environment.baseUrl}/placement/decline`,
      { id: placementId },
    );
  }

  getAllPlacementCollects(placementId: number) {
    return this.http.get<Response<PlacementRevenue[]>>(
      `${environment.baseUrl}/placement-collect/list`,
      {
        params: new HttpParams().set('job_placement_id', placementId),
      },
    );
  }

  deletePlacementCollect(id: number): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `${environment.baseUrl}/placement-collect`,
      {
        params: new HttpParams().set('id', id),
      },
    );
  }

  getPlacementData(placementId: number): Observable<Placement> {
    return this.http
      .get<Response<Placement>>(`${environment.baseUrl}/placement`, {
        params: new HttpParams().set('id', placementId),
      })
      .pipe(
        map((response) => {
          const { data } = response;

          return data;
        }),
        catchError((error) => throwError(() => error)),
      );
  }
}
