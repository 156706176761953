<ngb-toast
    *ngFor="let toast of toastService.toasts"
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 3000"
    (hidden)="toastService.remove(toast)">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
        <i
            class="bi me-1"
            [class.text-success]="toast?.type === 'success'"
            [class.bi-check-lg]="toast?.type === 'success'"
            [class.text-danger]="toast?.type === 'error'"
            [class.bi-exclamation-triangle]="toast?.type === 'error'"
            [class.text-primary]="toast?.type === 'info'"
            [class.bi-info-circle]="toast?.type === 'info'"></i
        >
        {{ toast.textOrTpl }}</ng-template
    >
</ngb-toast>
