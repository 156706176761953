import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { Placement } from 'src/app/shared/models/placement.model';
import { Response } from 'src/app/shared/models/response.model';
import { environment } from 'src/environments/environment';
import {
  MoneyDownPayment,
  MoneyDownPaymentHistory,
  MoneyDownSearch,
  MoneyDownSearchFilters,
} from '../../modules/stats/models/money-down-search.model';

export type CreateMoneyDownSearchBody = Pick<
  MoneyDownSearch,
  | 'job_title'
  | 'job_function_id'
  | 'job_sub_category_id'
  | 'company_size_id'
  | 'industry_id'
> & {
  money_down_payments: {
    payment: string;
    fee_amount: number;
    placement_date: string;
    id?: number;
  }[];
};

export type UpdateMoneyDownSearchBody = CreateMoneyDownSearchBody &
  Pick<MoneyDownSearch, 'id'>;

export interface CompleteMoneyDownSearchBody {
  placement_date: string;
  salary: number;
  start_date: string;
  fee_percent: number;
  fee_amount: number;
  inv_ref: number;

  is_premium: boolean;
  is_relocated: boolean;
  associate_assist: boolean;

  hiring_manager: boolean;
  human_resource: boolean;

  job_function_id?: number;
  job_sub_category_id?: number;
  company_size_id?: number;
  industry_id?: number;

  money_down_search_id: number;
}

@Injectable({
  providedIn: 'root',
})
export class MoneyDownService {
  private readonly baseUrl = `${environment.baseUrl}/money-down-search`;
  private readonly moneyDownPaymentUrl = `${environment.baseUrl}/money-down-payment`;

  constructor(private http: HttpClient) {}

  getMoneyDownSearches(
    options: MoneyDownSearchFilters,
  ): Observable<Response<MoneyDownSearch[]>> {
    return this.http.get<Response<MoneyDownSearch[]>>(`${this.baseUrl}/list`, {
      params: new HttpParams({ fromObject: options as any }),
    });
  }

  getMoneyDownSearchPayments(
    ids: number[],
  ): Observable<Response<MoneyDownPayment[]>> {
    return this.http.get<Response<MoneyDownPayment[]>>(
      this.moneyDownPaymentUrl,
      { params: new HttpParams().set('ids', ids.join(',')) },
    );
  }

  createMoneyDownSearch(
    data: CreateMoneyDownSearchBody,
  ): Observable<Response<MoneyDownSearch>> {
    return this.http.post<Response<MoneyDownSearch>>(this.baseUrl, data);
  }

  createMoneyDownPayment(
    data: Pick<
      MoneyDownPayment,
      'fee_amount' | 'payment' | 'money_down_search_id' | 'placement_date'
    >,
  ): Observable<Response<MoneyDownPayment>> {
    return this.http.post<Response<MoneyDownPayment>>(
      this.moneyDownPaymentUrl,
      data,
    );
  }

  updateMoneyDownSearch(
    data: Omit<UpdateMoneyDownSearchBody, 'money_down_payments'>,
    canEdit: boolean,
  ): Observable<Response<MoneyDownSearch>> {
    if (!canEdit) {
      return of({ data: { id: data.id } } as any);
    }
    return this.http.put<Response<MoneyDownSearch>>(this.baseUrl, data);
  }

  deleteMoneyDownSearch(id: number): Observable<Response<any>> {
    return this.http.delete<Response<any>>(this.baseUrl, {
      params: new HttpParams().set('id', id),
    });
  }

  completeMoneyDownSearch(
    body: CompleteMoneyDownSearchBody,
  ): Observable<Response<Placement>> {
    return this.http.post<Response<Placement>>(
      `${this.baseUrl}/complete`,
      body,
    );
  }

  changeMDSHiddenStatus(id: number, isHidden: boolean) {
    return this.http.patch<Response<any>>(this.baseUrl, {
      id,
      is_hidden: isHidden,
    });
  }

  getMoneyDownSearchData(
    moneyDownSearchId: number,
  ): Observable<MoneyDownSearch> {
    return this.http
      .get<Response<MoneyDownSearch>>(`${this.baseUrl}`, {
        params: new HttpParams().set('id', moneyDownSearchId),
      })
      .pipe(
        map((response) => {
          const { data } = response;

          return data;
        }),
        catchError((error) => throwError(() => error)),
      );
  }
}
