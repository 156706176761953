import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { omit, remove } from 'lodash/fp';

import {
    CompletePlacementHistory,
    Placement,
    PlacementDetails,
    PlacementRevenue,
} from '../../../shared/models/placement.model';
import * as PlacementActions from '../actions/placement.actions';

export const placementsFeatureKey = 'placements';

export interface State extends EntityState<Placement> {
    loaded: boolean;
    error?: string;
    selectedPlacement?: PlacementDetails;
    message?: string;
    collects: Record<number, PlacementRevenue>;
    fallouts: Record<number, PlacementRevenue>;
    history: Record<number, CompletePlacementHistory>;
}

export const adapter: EntityAdapter<Placement> =
    createEntityAdapter<Placement>();

export const initialState: State = adapter.getInitialState({
    loaded: false,
    collects: {},
    fallouts: {},
    history: {},
});

/**
 * Rehydrate by removing one time properties
 * @param state Previous State
 * @returns New State
 */
export const rehydrate = (state: State): State => {
    return {
        ...state,
        message: undefined,
    };
};

export const reducer = createReducer(
    initialState,
    on(PlacementActions.loadPlacementsSuccess, (state, action) =>
        adapter.setAll(action.placements, {
            ...rehydrate(state),
            loaded: true,
        }),
    ),
    on(PlacementActions.upsertPlacementSuccess, (state, action) =>
        adapter.upsertOne(action.placement, rehydrate(state)),
    ),
    on(PlacementActions.deletePlacementSuccess, (state, action) =>
        adapter.removeOne(action.id, rehydrate(state)),
    ),
    /** Error handling */
    on(
        PlacementActions.loadPlacementsFailure,
        PlacementActions.addPlacementFailure,
        PlacementActions.upsertPlacementFailure,
        PlacementActions.deletePlacementFailure,
        PlacementActions.deletePlacementCollectFailure,
        (state, action) => {
            const { status, statusText } = action.error;

            return { ...rehydrate(state), error: `${status}: ${statusText}` };
        },
    ),
    on(PlacementActions.setPlacementDetails, (state, action) => {
        return {
            ...rehydrate(state),
            selectedPlacement: action.placementDetails,
        };
    }),
    on(PlacementActions.setMessage, (state, action) => {
        return {
            ...rehydrate(state),
            message: action.message,
        };
    }),
    on(PlacementActions.setCollects, (state, action) => {
        return {
            ...rehydrate(state),
            collects: {
                ...state.collects,
                ...action.collects,
            },
        };
    }),
    on(PlacementActions.setFallouts, (state, action) => {
        return {
            ...rehydrate(state),
            fallouts: {
                ...state.fallouts,
                ...action.fallouts,
            },
        };
    }),
    on(PlacementActions.setPlacementHistory, (state, action) => {
        return {
            ...rehydrate(state),
            history: {
                ...history,
                [action.placementId]: action.placementHistory,
            },
        };
    }),
    on(PlacementActions.deletePlacementCollectSuccess, (state, action) => ({
        ...rehydrate(state),
        collects: omit(
            action.placementCollectId.toString(),
            state.collects,
        ) as Record<number, PlacementRevenue>,

        history: {
            ...state.history,
            [action.placementId]: {
                ...state.history[action.placementId],
                collects: remove(
                    { id: action.placementCollectId },
                    state.history[action.placementId].collects,
                ),
            },
        } as Record<number, CompletePlacementHistory>,
    })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
    adapter.getSelectors();
