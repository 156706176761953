import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    concatMap,
    from,
    map,
    of,
    switchMap,
    withLatestFrom,
} from 'rxjs';

import { OtherFeeService } from '../../../modules/stats/services/other-fee.service';
import * as fromActions from '../actions/other-fee.actions';

@Injectable()
export class OtherFeeEffects {
    constructor(
        private actions$: Actions,
        private otherFeeService: OtherFeeService,
    ) {}

    loadOtherFees$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.loadOtherFees),
            map((action) => action.filters),
            switchMap((filters) => {
                return this.otherFeeService.getOtherFees(filters).pipe(
                    map((response) => {
                        return fromActions.loadOtherFeesSuccess({
                            otherFees: response,
                        });
                    }),
                    catchError((error) => {
                        return of(
                            fromActions.loadOtherFeesFailure({
                                error: error,
                            }),
                        );
                    }),
                );
            }),
        );
    });

    createOtherFee$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.addOtherFee),
            map((action) => action.otherFee),
            withLatestFrom(
                this.actions$.pipe(ofType(fromActions.loadOtherFees)),
            ),
            switchMap(([item, previousLoadOtherFeeAction]) => {
                return this.otherFeeService.createOtherFee(item).pipe(
                    concatMap(() =>
                        from([
                            fromActions.loadOtherFees({
                                filters: previousLoadOtherFeeAction.filters,
                            }),
                            fromActions.addOtherFeeSuccess(),
                        ]),
                    ),
                    catchError((error) => {
                        return of(
                            fromActions.addOtherFeeFailure({
                                error: error,
                            }),
                        );
                    }),
                );
            }),
        );
    });

    updateOtherFee$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.upsertOtherFee),
            map((action) => action.otherFee),
            switchMap((item) => {
                return this.otherFeeService.updateOtherFee(item).pipe(
                    map((response) =>
                        fromActions.upsertOtherFeeSuccess({
                            otherFee: response,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActions.upsertOtherFeeFailure({
                                error: error,
                            }),
                        ),
                    ),
                );
            }),
        );
    });

    deleteOtherFee$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.deleteOtherFee),
            map((action) => action.id),
            switchMap((item) => {
                return this.otherFeeService.deleteOtherFee(item).pipe(
                    map(() =>
                        fromActions.deleteOtherFeeSuccess({
                            id: item,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActions.deleteOtherFeeFailure({
                                error: error,
                            }),
                        ),
                    ),
                );
            }),
        );
    });
}
