import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    constructor() {}

    createCookie(name: string, value: any, days: number = 14) {
        let expires;

        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();

        document.cookie = name + '=' + value + expires + '; path=/';
    }

    getCookie(name: string) {
        if (document.cookie.length > 0) {
            let start = document.cookie.indexOf(name + '=');
            if (start != -1) {
                start = start + name.length + 1;
                let end = document.cookie.indexOf(';', start);
                if (end == -1) {
                    end = document.cookie.length;
                }
                return unescape(document.cookie.substring(start, end));
            }
        }
        return '';
    }

    deleteCookie(name: string) {
        if (this.getCookie(name)) {
            const domain = window.location.hostname;
            const subdomain = '.' + domain.split('.').slice(1).join('.');

            const domains = [domain, subdomain];

            domains.forEach((domainValue) => {
                document.cookie =
                    name +
                    `=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domainValue}`;
            });
        }
    }
}
