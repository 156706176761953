import { createAction, props } from '@ngrx/store';
import RemoteResource from 'src/app/shared/models/resource.model';
import {
    CompanySize,
    Industry,
    JobFunction,
    JobFunctionSubcategory,
    Office,
    Recruiter,
    UserRole,
} from '../../../modules/stats/models/metadata.model';

export const getMetadata = createAction('[Metadata] Get Metadata');

export const getJobFunctionSubCategory = createAction(
    '[Metadata] Get Job Function Subcategory',
    props<{ jobFunctionId: number }>(),
);

export const setJobFunctions = createAction(
    '[Metadata] Set Job Functions',
    props<{ result: RemoteResource<JobFunction[]> }>(),
);

export const setJobFunctionSubCategories = createAction(
    '[Metadata] Set Job Function Sub Categories',
    props<{ result: RemoteResource<JobFunctionSubcategory[]> }>(),
);

export const setIndustries = createAction(
    '[Metadata] Set Industries',
    props<{ result: RemoteResource<Industry[]> }>(),
);

export const setCompanySizes = createAction(
    '[Metadata] Set Company Sizes',
    props<{ result: RemoteResource<CompanySize[]> }>(),
);

export const getRecruiters = createAction('[Metadata] Get Recruiters');

export const setRecruiters = createAction(
    '[Metadata] Set Recruiters',
    props<{ result: RemoteResource<Recruiter[]> }>(),
);

export const getOffices = createAction('[Metadata] Get Offices');

export const setOffices = createAction(
    '[Metadata] Set Offices',
    props<{ result: RemoteResource<Office[]> }>(),
);

export const getUserRoles = createAction(
    '[Metadata] Get User Roles',
    props<{ includeDisabled: boolean }>(),
);

export const setUserRoles = createAction(
    '[Metadata] Set User Roles',
    props<{ result: RemoteResource<UserRole[]> }>(),
);
