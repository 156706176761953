import { Component, TemplateRef, OnInit } from '@angular/core';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    host: {
        class: 'toast-container position-fixed top-0 start-50 translate-middle-x p-3',
        style: 'z-index: 1200',
    },
})
export class ToastComponent implements OnInit {
    constructor(public toastService: ToastService) {}

    ngOnInit(): void {}

    isTemplate(toast: any) {
        return toast.textOrTpl instanceof TemplateRef;
    }
}
