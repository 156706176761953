import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  from,
  map,
  merge,
  of,
  switchMap,
  withLatestFrom,
  zip,
} from 'rxjs';

import { Store } from '@ngrx/store';
import { ResourceState } from 'src/app/shared/models/resource.model';
import { MoneyDownSearchDetails } from '../../../modules/stats/models/money-down-search.model';
import { MoneyDownService } from '../../../shared/services/money-down.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as fromActions from '../actions/money-down-search.actions';
import * as fromMetadataActions from '../actions/metadata.actions';
import type { FeatureState } from '../reducers';
import { getMetadataState, getMoneyDownSearchState } from '../selectors';

@Injectable()
export class MoneyDownSearchEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<FeatureState>,
    private moneyDownSearchService: MoneyDownService,
    private toastService: ToastService,
  ) {}

  loadMoneyDownSearches$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadMoneyDownSearches),
      switchMap(({ filters }) => {
        return this.moneyDownSearchService.getMoneyDownSearches(filters).pipe(
          map((response) => {
            return fromActions.loadMoneyDownSearchesSuccess({
              moneyDownSearches: response.data,
            });
          }),
          catchError((error) => {
            return of(
              fromActions.loadMoneyDownSearchesFailure({
                error: error,
              }),
            );
          }),
        );
      }),
    );
  });

  loadMoneyDownSearchPayments$ = createEffect(() => {
    return merge(
      this.actions$.pipe(
        ofType(fromActions.loadMoneyDownSearchesSuccess),
        map((it) => it.moneyDownSearches),
      ),
      this.actions$.pipe(
        ofType(fromActions.addMoneyDownSearchSuccess),
        map((it) => [it.data]),
      ),
    ).pipe(
      switchMap((moneyDownSearches) => {
        const ids = moneyDownSearches
          .flatMap((it) => it.money_down_payment_ids)
          .filter((it) => !!it);
        return this.moneyDownSearchService.getMoneyDownSearchPayments(ids).pipe(
          map((response) =>
            fromActions.setMoneyDownSearchPayments({
              moneyDownPayments: response.data,
            }),
          ),
        );
      }),
    );
  });

  createMoneyDownSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.addMoneyDownSearch),
      map((action) => action.data),
      switchMap((item) => {
        return this.moneyDownSearchService.createMoneyDownSearch(item).pipe(
          map(({ data }) => fromActions.addMoneyDownSearchSuccess({ data })),
          catchError((error) => {
            this.toastService.show('Error, could not complete request', {
              type: 'error',
            });

            return of(
              fromActions.addMoneyDownSearchFailure({
                error: error,
              }),
            );
          }),
        );
      }),
    );
  });

  updateMoneyDownSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.upsertMoneyDownSearch),
      map((action) => action),
      switchMap((action) => {
        const { moneyDownSearch: item, canEdit } = action;        
        const { money_down_payments: mdsPayments, ...mdsRequestData } = item;

        const paymentUpdates = mdsPayments
          .filter((it) => !(it as any).id)
          .map(({ payment, fee_amount, placement_date }) =>
            this.moneyDownSearchService.createMoneyDownPayment({
              payment,
              fee_amount,
              money_down_search_id: item.id,
              placement_date,
            }),
          );
        // Create the new payments
        return (
          paymentUpdates.length === 0 ? of([]) : zip(paymentUpdates)
        ).pipe(
          // Then just updating the money down search should return with the new payments.
          switchMap((response) => {
            const moneyDownPayments = response.map((it) => it.data);
            return this.moneyDownSearchService
              .updateMoneyDownSearch(mdsRequestData, canEdit)
              .pipe(
                concatMap((response) =>
                  from([
                    fromActions.setMoneyDownSearchPayments({
                      moneyDownPayments,
                    }),
                    fromActions.upsertMoneyDownSearchSuccess({
                      moneyDownSearch: response.data,
                    }),
                  ]),
                ),
                catchError((error) => {
                  this.toastService.show('Error, could not complete request', {
                    type: 'error',
                  });

                  return of(
                    fromActions.upsertMoneyDownSearchFailure({
                      error: error,
                    }),
                  );
                }),
              );
          }),
        );
      }),
    );
  });

  deleteMoneyDownSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteMoneyDownSearch),
      map((action) => action.id),
      switchMap((item) => {
        return this.moneyDownSearchService.deleteMoneyDownSearch(item).pipe(
          map(() =>
            fromActions.deleteMoneyDownSearchSuccess({
              id: item,
            }),
          ),
          catchError((error) =>
            of(
              fromActions.deleteMoneyDownSearchFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    );
  });

  getMoneyDownSearchDetails$ = createEffect(() => {
    const mergedActions = merge(
      this.actions$.pipe(
        ofType(fromActions.upsertMoneyDownSearchSuccess),
        map((it) =>
          fromActions.selectMoneyDownSearch({
            id: it.moneyDownSearch.id,
          }),
        ),
      ),
      this.actions$.pipe(
        ofType(fromActions.completeMoneyDownSearchSuccess),
        map((it) =>
          fromActions.selectMoneyDownSearch({
            id: it.moneyDownSearchId,
          }),
        ),
      ),
      this.actions$.pipe(
        ofType(fromActions.selectMoneyDownSearch),
        distinctUntilChanged(
          (oldAction, newAction) => oldAction.id === newAction.id,
        ),
      ),
    );
    return combineLatest([
      mergedActions.pipe(
        withLatestFrom(this.store$.select(getMoneyDownSearchState)),
        map(([action, state]) =>
          action.id ? state.entities[action.id]! : undefined,
        ),
      ),
      this.store$.select(getMetadataState).pipe(
        filter((metadataState) => {
          return [
            metadataState.jobFunctions.status,
            metadataState.industries.status,
            metadataState.jobFunctionSubCategories.status,
            metadataState.companySizes.status,
            metadataState.recruiters.status,
          ].every((it) => it === ResourceState.SUCCESS);
        }),
      ),
    ]).pipe(
      map(([moneyDownSearch, metadataState]) => {
        if (!moneyDownSearch) {
          return fromActions.setMoneyDownSearchDetails({});
        }

        const jobSubCategory = metadataState.jobFunctionSubCategories
          .getData()
          .find((it) => it.id === moneyDownSearch.job_sub_category_id);

        /**
         * If Job Function Subcategory does not exist then send a request to fetch it first
         */

        if (
          !jobSubCategory &&
          !!moneyDownSearch.job_sub_category_id &&
          !!moneyDownSearch.job_function_id
        ) {
          return fromMetadataActions.getJobFunctionSubCategory({
            jobFunctionId: moneyDownSearch.job_function_id!,
          });
        }

        /**
         * Otherwise prepare the details object with subcategory
         */
        const moneyDownSearchDetails: MoneyDownSearchDetails = {
          ...moneyDownSearch!,
          job_function: metadataState.jobFunctions
            .getData()
            .find((it) => it.id === moneyDownSearch.job_function_id),
          job_subcategory: jobSubCategory,
          industry: metadataState.industries
            .getData()
            .find((it) => it.id === moneyDownSearch.industry_id),
          company_size: metadataState.companySizes
            .getData()
            .find((it) => it.id === moneyDownSearch.company_size_id),
          owner: metadataState.recruiters
            .getData()
            .find((it) => it.id === moneyDownSearch.search_owner),
        };

        return fromActions.setMoneyDownSearchDetails({
          moneyDownSearchDetails,
        });
      }),
    );
  });

  completeMoneyDownSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.completeMoneyDownSearch),
      switchMap(({ body }) => {
        return this.moneyDownSearchService.completeMoneyDownSearch(body);
      }),
      map((response) =>
        fromActions.completeMoneyDownSearchSuccess({
          moneyDownSearchId: response.data.money_down_search_id!,
        }),
      ),
    );
  });
}
