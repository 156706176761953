import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

import { Response } from 'src/app/shared/models/response.model';
import { environment } from 'src/environments/environment';
import { OtherFee, OtherFeeFilters } from '../models/other-fee.model';

@Injectable({
    providedIn: 'root',
})
export class OtherFeeService {
    private readonly baseUrl = `${environment.baseUrl}/other-fee`;
    constructor(private http: HttpClient) {}

    getOtherFees(filters: Partial<OtherFeeFilters>): Observable<OtherFee[]> {
        let params = new HttpParams();

        Object.entries(filters)
            .filter(([, value]) => value !== undefined)
            .forEach(([key, value]) => {
                params = params.set(key, value);
            });

        return this.http
            .get<Response<OtherFee[]>>(`${this.baseUrl}/list`, {
                params,
            })
            .pipe(
                map((response) => {
                    const { data } = response;

                    return data;
                }),
                catchError((error) => throwError(() => error)),
            );
    }

    createOtherFee(otherFee: OtherFee): Observable<OtherFee> {
        // TODO Remove this when IDP has been implemented locally
        return this.http.post<Response<OtherFee>>(this.baseUrl, otherFee).pipe(
            map((response) => {
                const { data } = response;
                return data;
            }),
            catchError((error) => throwError(() => error)),
        );
    }

    updateOtherFee(payload: Partial<OtherFee>): Observable<OtherFee> {
        return this.http.put<Response<OtherFee>>(this.baseUrl, payload).pipe(
            map((response) => {
                const { data } = response;

                return data;
            }),
            catchError((error) => throwError(() => error)),
        );
    }

    deleteOtherFee(id: number): Observable<OtherFee> {
        const params = new HttpParams().set('id', id);

        return this.http
            .delete<OtherFee>(this.baseUrl, {
                params,
            })
            .pipe(catchError((error) => throwError(() => error)));
    }
}
