import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { union, unionBy } from 'lodash';
import {
    MoneyDownPayment,
    MoneyDownSearch,
    MoneyDownSearchDetails,
} from '../../../modules/stats/models/money-down-search.model';
import * as MoneyDownSearchActions from '../actions/money-down-search.actions';

export const moneyDownSearchFeatureKey = 'moneyDownSearch';

export interface State extends EntityState<MoneyDownSearch> {
    loaded: boolean;
    error?: string;
    selectedMoneyDownSearch?: MoneyDownSearchDetails;
    message?: string;
    moneyDownPayments: MoneyDownPayment[];
}

export const adapter: EntityAdapter<MoneyDownSearch> =
    createEntityAdapter<MoneyDownSearch>();

export const initialState: State = adapter.getInitialState({
    loaded: false,
    moneyDownPayments: [],
});

/**
 * Rehydrate by removing one time properties
 * @param state Previous State
 * @returns New State
 */
export const rehydrate = (state: State): State => {
    return {
        ...state,
        message: undefined,
    };
};

export const reducer = createReducer(
    initialState,

    on(MoneyDownSearchActions.loadMoneyDownSearchesSuccess, (state, action) =>
        adapter.setAll(action.moneyDownSearches, {
            ...rehydrate(state),
            loaded: true,
        }),
    ),
    on(MoneyDownSearchActions.upsertMoneyDownSearchSuccess, (state, action) =>
        adapter.upsertOne(action.moneyDownSearch, rehydrate(state)),
    ),
    on(MoneyDownSearchActions.deleteMoneyDownSearchSuccess, (state, action) =>
        adapter.removeOne(action.id, rehydrate(state)),
    ),
    on(MoneyDownSearchActions.addMoneyDownSearchSuccess, (state, action) =>
        adapter.addOne(action.data, state),
    ),
    /** Error handling */
    on(
        MoneyDownSearchActions.loadMoneyDownSearchesFailure,
        MoneyDownSearchActions.addMoneyDownSearchFailure,
        MoneyDownSearchActions.upsertMoneyDownSearchFailure,
        MoneyDownSearchActions.deleteMoneyDownSearchFailure,
        (state, action) => {
            const { status, statusText } = action.error;

            return { ...rehydrate(state), error: `${status}: ${statusText}` };
        },
    ),
    on(MoneyDownSearchActions.setMoneyDownSearchDetails, (state, action) => {
        return {
            ...rehydrate(state),
            selectedMoneyDownSearch: action.moneyDownSearchDetails,
        };
    }),
    on(MoneyDownSearchActions.setMoneyDownSearchPayments, (state, action) => {
        return {
            ...rehydrate(state),
            moneyDownPayments: unionBy(
                state.moneyDownPayments,
                action.moneyDownPayments,
                'id',
            ),
        };
    }),
    on(
        MoneyDownSearchActions.completeMoneyDownSearchSuccess,
        (state, action) => {
            return adapter.updateOne(
                {
                    changes: { is_completed: true },
                    id: action.moneyDownSearchId,
                },
                rehydrate(state),
            );
        },
    ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
    adapter.getSelectors();
