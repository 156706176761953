import { createReducer, on } from '@ngrx/store';
import RemoteResource from 'src/app/shared/models/resource.model';
import {
    CompanySize,
    Industry,
    JobFunction,
    JobFunctionSubcategory,
    Office,
    Recruiter,
    UserRole,
} from '../../../modules/stats/models/metadata.model';
import * as MetadataActions from '../actions/metadata.actions';

export const metadataFeatureKey = 'metadata';

export interface State {
    jobFunctions: RemoteResource<JobFunction[]>;
    jobFunctionSubCategories: RemoteResource<JobFunctionSubcategory[]>;
    industries: RemoteResource<Industry[]>;
    companySizes: RemoteResource<CompanySize[]>;
    recruiters: RemoteResource<Recruiter[]>;
    offices: RemoteResource<Office[]>;
    userRoles: RemoteResource<UserRole[]>;
}

export const initialState: State = {
    jobFunctions: RemoteResource.loading(),
    jobFunctionSubCategories: RemoteResource.loading(),
    industries: RemoteResource.loading(),
    companySizes: RemoteResource.loading(),
    recruiters: RemoteResource.loading(),
    offices: RemoteResource.loading(),
    userRoles: RemoteResource.loading(),
};

export const reducer = createReducer(
    initialState,
    on(MetadataActions.setJobFunctions, (state, action) => ({
        ...state,
        jobFunctions: action.result,
    })),
    on(MetadataActions.setJobFunctionSubCategories, (state, action) => ({
        ...state,
        jobFunctionSubCategories: action.result,
    })),
    on(MetadataActions.setIndustries, (state, action) => ({
        ...state,
        industries: action.result,
    })),
    on(MetadataActions.setCompanySizes, (state, action) => ({
        ...state,
        companySizes: action.result,
    })),
    on(MetadataActions.setRecruiters, (state, action) => ({
        ...state,
        recruiters: action.result,
    })),
    on(MetadataActions.setOffices, (state, action) => ({
        ...state,
        offices: action.result,
    })),
    on(MetadataActions.setUserRoles, (state, action) => ({
        ...state,
        userRoles: action.result,
    })),
);
