export enum ResourceState {
    LOADING,
    SUCCESS,
    ERROR,
}

export default class RemoteResource<T> {
    readonly status: ResourceState;

    private constructor(
        private readonly data?: T | null,
        private readonly error?: Error,
    ) {
        if (data !== undefined) {
            this.status = ResourceState.SUCCESS;
            return;
        }
        if (error !== undefined) {
            this.status = ResourceState.ERROR;
            return;
        }

        this.status = ResourceState.LOADING;
    }

    static loading<T>(): RemoteResource<T> {
        return new RemoteResource();
    }

    static success<T>(data: T): RemoteResource<T> {
        return new RemoteResource(data);
    }

    static error<T>(error: Error): RemoteResource<T> {
        return new RemoteResource<T>(undefined, error);
    }

    getDataOrEmpty(): T | undefined {
        return this.data ?? undefined;
    }

    getData(): T {
        return this.data!;
    }

    getError(): Error {
        return this.error!;
    }
}
