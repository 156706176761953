import { createAction, props } from '@ngrx/store';

import {
  CompletePlacementHistory,
  Placement,
  PlacementDetails,
  PlacementFilters,
  PlacementRevenue,
} from '../../../shared/models/placement.model';

/**
 *
 * Load placements */
export const loadPlacements = createAction(
  '[Stats] Load Placements',
  props<{ filters: Partial<PlacementFilters> }>(),
);

export const loadPlacementsSuccess = createAction(
  '[Stats] Load Placements Success',
  props<{
    placements: Placement[];
  }>(),
);

export const loadPlacementsFailure = createAction(
  '[Stats] Load Placements Failure',
  props<{ error: any }>(),
);

/**
 *
 * Add placement */
export const addPlacement = createAction(
  '[Stats] Add Placement',
  props<{ placement: Placement }>(),
);

export const addPlacementSuccess = createAction(
  '[Stats] Add Placement Success',
);

export const addPlacementFailure = createAction(
  '[Stats] Add Placement Failure',
  props<{ error: any }>(),
);

/**
 *
 * Upsert placement */
export const upsertPlacement = createAction(
  '[Stats] Upsert Placement',
  props<{ placement: Partial<Placement> }>(),
);

export const upsertPlacementSuccess = createAction(
  '[Stats] Upsert Placement Success',
  props<{ placement: Placement }>(),
);

export const upsertPlacementFailure = createAction(
  '[Stats] Upsert Placement Failure',
  props<{ error: any }>(),
);

/**
 *
 * Delete placement */
export const deletePlacement = createAction(
  '[Stats] Delete Placement',
  props<{ id: number }>(),
);

export const deletePlacementSuccess = createAction(
  '[Stats] Delete Placement Success',
  props<{ id: number }>(),
);

export const deletePlacementFailure = createAction(
  '[Stats] Delete Placement Failure',
  props<{ error: any }>(),
);

// Select Placement
export const selectPlacement = createAction(
  '[Stats] Select Placement',
  props<{ id?: number }>(),
);

export const setPlacementDetails = createAction(
  '[Stats] Set Placement Details',
  props<{ placementDetails?: PlacementDetails }>(),
);

// Collect & Fallout

export const markPlacementCollect = createAction(
  '[Stats] Mark Placement as Collected',
  props<{
    fee_amount: number;
    note: string;
    placement_date: string;
    mark_collected?: boolean;
  }>(),
);

export const markPlacementFallout = createAction(
  '[Stats] Mark Placement as Fallout',
  props<{ fee_amount: number; note: string; placement_date: string }>(),
);

export const setCollects = createAction(
  '[Stats] Set Collects',
  props<{ collects: Record<number, PlacementRevenue> }>(),
);

export const setFallouts = createAction(
  '[Stats] Set Fallouts',
  props<{ fallouts: Record<number, PlacementRevenue> }>(),
);

// History

export const getPlacementHistory = createAction(
  '[Stats] Get Placement History',
  props<{ placementId: number; moneyDownSearchId: number | null }>(),
);

export const setPlacementHistory = createAction(
  '[Stats] Set Placement History',
  props<{
    placementId: number;
    placementHistory: CompletePlacementHistory;
  }>(),
);

export const deletePlacementCollect = createAction(
  '[Stats] Delete Placement History',
  props<{
    placementCollectId: number;
    placementId: number;
  }>(),
);

export const deletePlacementCollectSuccess = createAction(
  '[Stats] Delete Placement History Success',
  props<{ placementCollectId: number; placementId: number }>(),
);

export const deletePlacementCollectFailure = createAction(
  '[Stats] Delete Placement History Failure',
  props<{ error: any }>(),
);

// Common

export const setMessage = createAction(
  '[Stats] Set Message',
  props<{ message?: string }>(),
);
