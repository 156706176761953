import { createSelector } from '@ngrx/store';
import { General } from '../../../modules/stats/models/general.model';

import * as fromFeature from '../reducers';
import * as fromGeneral from '../reducers/general.reducer';

export const getGeneralState = createSelector(
    fromFeature.getFeatureState,
    (state: fromFeature.FeatureState) => state.generals,
);

export const getGeneralEntities = createSelector(
    getGeneralState,
    fromGeneral.selectEntities,
);

export const getAllGenerals = createSelector(getGeneralEntities, (entities) => {
    return Object.values(entities) as General[];
});
