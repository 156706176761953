import { createAction, props } from '@ngrx/store';
import {
    OtherFeeFilters,
    OtherFee,
} from '../../../modules/stats/models/other-fee.model';

/**
 *
 * Load otherFees */
export const loadOtherFees = createAction(
    '[Stats] Load OtherFees',
    props<{ filters: Partial<OtherFeeFilters> }>(),
);

export const loadOtherFeesSuccess = createAction(
    '[Stats] Load OtherFees Success',
    props<{
        otherFees: OtherFee[];
    }>(),
);

export const loadOtherFeesFailure = createAction(
    '[Stats] Load OtherFees Failure',
    props<{ error: any }>(),
);

/**
 *
 * Add otherFee */
export const addOtherFee = createAction(
    '[Stats] Add OtherFee',
    props<{ otherFee: OtherFee }>(),
);

export const addOtherFeeSuccess = createAction('[Stats] Add OtherFee Success');

export const addOtherFeeFailure = createAction(
    '[Stats] Add OtherFee Failure',
    props<{ error: any }>(),
);

/**
 *
 * Upsert otherFee */
export const upsertOtherFee = createAction(
    '[Stats] Upsert OtherFee',
    props<{ otherFee: Partial<OtherFee> }>(),
);

export const upsertOtherFeeSuccess = createAction(
    '[Stats] Upsert OtherFee Success',
    props<{ otherFee: OtherFee }>(),
);

export const upsertOtherFeeFailure = createAction(
    '[Stats] Upsert OtherFee Failure',
    props<{ error: any }>(),
);

/**
 *
 * Delete otherFee */
export const deleteOtherFee = createAction(
    '[Stats] Delete OtherFee',
    props<{ id: number }>(),
);

export const deleteOtherFeeSuccess = createAction(
    '[Stats] Delete OtherFee Success',
    props<{ id: number }>(),
);

export const deleteOtherFeeFailure = createAction(
    '[Stats] Delete OtherFee Failure',
    props<{ error: any }>(),
);
