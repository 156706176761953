import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { Placement } from '../../../shared/models/placement.model';

import * as fromFeature from '../reducers';
import * as fromPlacement from '../reducers/placement.reducer';

export const getPlacementState = createSelector(
    fromFeature.getFeatureState,
    (state: fromFeature.FeatureState) => state.placements,
);

export const getPlacementEntities = createSelector(
    getPlacementState,
    fromPlacement.selectEntities,
);

export const getAllPlacements = createSelector(
    getPlacementEntities,
    (entities) => {
        return (Object.values(entities) as Placement[]).sort((a, b) => {
            return (
                moment(b.created_datetime).unix() -
                moment(a.created_datetime).unix()
            );
        });
    },
);
