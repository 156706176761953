import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  withLatestFrom,
  zip,
} from 'rxjs';

import { Store } from '@ngrx/store';
import { ResourceState } from 'src/app/shared/models/resource.model';
import {
  PlacementDetails,
  PlacementRevenue,
} from '../../../shared/models/placement.model';
import { PlacementService } from '../../../shared/services/placement.service';
import * as fromActions from '../actions/placement.actions';
import * as fromMetadataActions from '../actions/metadata.actions';
import type { FeatureState } from '../reducers';
import { getMetadataState, getPlacementState } from '../selectors';
import { Response } from 'src/app/shared/models/response.model';
import { flatten } from 'lodash';
import { loadActivities } from '../../activity';
import moment from 'moment';
import { ToastService } from 'src/app/shared/services/toast.service';

@Injectable()
export class PlacementEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<FeatureState>,
    private placementService: PlacementService,
    private toastService: ToastService,
  ) {}

  loadPlacements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadPlacements),
      map((action) => action.filters),
      switchMap((filters) => {
        return this.placementService.getPlacements(filters).pipe(
          map((response) => {
            return fromActions.loadPlacementsSuccess({
              placements: response,
            });
          }),
          catchError((error) => {
            return of(
              fromActions.loadPlacementsFailure({
                error: error,
              }),
            );
          }),
        );
      }),
    );
  });

  createPlacement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.addPlacement),
      map((action) => action.placement),
      switchMap((item) => {
        return this.placementService.createPlacement(item).pipe(
          concatMap(() => from([fromActions.addPlacementSuccess()])),
          catchError((error) => {
            this.toastService.show('Error could not complete request', {
              type: 'error',
            });

            return of(
              fromActions.addPlacementFailure({
                error: error,
              }),
            );
          }),
        );
      }),
    );
  });

  updatePlacement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.upsertPlacement),
      map((action) => action.placement),
      switchMap((item) => {
        return this.placementService.updatePlacement(item).pipe(
          map((response) =>
            fromActions.upsertPlacementSuccess({
              placement: response,
            }),
          ),
          catchError((error) =>
            of(
              fromActions.upsertPlacementFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    );
  });

  deletePlacement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deletePlacement),
      map((action) => action.id),
      switchMap((item) => {
        return this.placementService.deletePlacement(item).pipe(
          map(() =>
            fromActions.deletePlacementSuccess({
              id: item,
            }),
          ),
          catchError((error) =>
            of(
              fromActions.deletePlacementFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    );
  });

  getPlacementDetails$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType(fromActions.selectPlacement),
        distinctUntilChanged(
          (oldAction, newAction) => oldAction.id === newAction.id,
        ),
        withLatestFrom(this.store$.select(getPlacementState)),
        map(([action, state]) =>
          action.id ? state.entities[action.id]! : null,
        ),
        distinctUntilChanged(
          (oldState, newState) => oldState?.id === newState?.id,
        ),
      ),
      this.store$.select(getMetadataState).pipe(
        filter((metadataState) => {
          return [
            metadataState.jobFunctions.status,
            metadataState.industries.status,
            metadataState.jobFunctionSubCategories.status,
            metadataState.companySizes.status,
            metadataState.recruiters.status,
          ].every((it) => it === ResourceState.SUCCESS);
        }),
      ),
    ]).pipe(
      map(([placement, metadataState]) => {
        if (!placement) {
          return fromActions.setPlacementDetails({});
        }

        const jobSubCategory = metadataState.jobFunctionSubCategories
          .getData()
          .find((it) => it.id === placement.job_sub_category_id);

        /**
         * If Job Function Subcategory does not exist then send a request to fetch it first
         */

        if (
          !jobSubCategory &&
          !!placement.job_sub_category_id &&
          !!placement.job_function_id
        ) {
          return fromMetadataActions.getJobFunctionSubCategory({
            jobFunctionId: placement.job_function_id!,
          });
        }

        /**
         * Otherwise prepare the details object with subcategory
         */
        const placementDetails: PlacementDetails = {
          ...placement!,
          job_function: metadataState.jobFunctions
            .getData()
            .find((it) => it.id === placement.job_function_id),
          job_sub_category: jobSubCategory,
          industry: metadataState.industries
            .getData()
            .find((it) => it.id === placement.industry_id),
          company_size: metadataState.companySizes
            .getData()
            .find((it) => it.id === placement.company_size_id),
          owner: metadataState.recruiters
            .getData()
            .find((it) => it.id === placement.placement_owner),
          payments: [],
        };

        if (placement.placement_collect_ids) {
          placementDetails.placement_collects =
            placement.placement_collect_ids.map((id) => ({ id }));
        }

        return fromActions.setPlacementDetails({ placementDetails });
      }),
    );
  });

  markPlacementAsCollected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.markPlacementCollect),
      withLatestFrom(
        this.store$
          .select(getPlacementState)
          .pipe(map((state) => state.selectedPlacement)),
      ),
      filter(([, placement]) => !!placement),
      switchMap(([action, placement]) => {
        const { fee_amount, note, placement_date, mark_collected } = action;
        let request!: Observable<Response<PlacementRevenue>>;
        request = this.placementService.markPlacementAsCollected(
          placement?.id!,
          {
            fee_amount,
            note,
            placement_date,
          },
        );
        // const onComplete = () => {
        //   if (mark_collected) {
        //     return fromActions.deletePlacementSuccess({
        //       id: placement!.id,
        //     });
        //   }
        //   return of(null);
        // };

        return request.pipe(
          concatMap((response) => {
            const actionsToDispatch = [
              fromActions.setCollects({
                collects: {
                  [response.data.id]: response.data,
                },
              }),
              fromActions.upsertPlacementSuccess({
                placement: {
                  ...(placement as any),
                  placement_collect_ids: placement?.placement_collects
                    ?.map((it) => it.id)
                    ?.concat(response.data.id),
                  placement_fallout_ids: placement?.placement_fallouts?.map(
                    (it) => it.id,
                  ),
                },
              }),
              fromActions.setPlacementDetails({
                placementDetails: {
                  ...placement!,
                  placement_collect_ids: placement?.placement_collects
                    ?.map((it) => it.id)
                    ?.concat(response.data.id),
                  placement_collects: placement?.placement_collects?.concat(
                    response.data,
                  ),
                } as any,
              }),
            ];

            if (mark_collected) {
              actionsToDispatch.push(
                fromActions.deletePlacementSuccess({
                  id: placement!.id,
                }) as any,
              );
            }

            return from(actionsToDispatch);
          }),
        );
      }),
    );
  });

  markPlacementAsFallout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.markPlacementFallout),
      withLatestFrom(
        this.store$
          .select(getPlacementState)
          .pipe(map((state) => state.selectedPlacement)),
      ),
      filter(([, placement]) => !!placement),
      switchMap(([action, placement]) => {
        const { fee_amount, note, placement_date } = action;
        let request!: Observable<Response<PlacementRevenue>>;
        request = this.placementService.markPlacementAsFallout(placement?.id!, {
          fee_amount,
          note,
          placement_date,
        });
        return request.pipe(
          concatMap((response) =>
            from([
              fromActions.setFallouts({
                fallouts: {
                  [response.data.id]: response.data,
                },
              }),
              fromActions.deletePlacementSuccess({
                id: placement!.id,
              }),
              fromActions.setPlacementDetails({
                placementDetails: undefined,
              }),
            ]),
          ),
        );
      }),
    );
  });

  private arrayToDictionary = <T extends { id: number }>(
    items: T[],
  ): Record<number, T> => {
    const result: Record<number, T> = {};

    for (const curr of items) {
      result[curr.id] = curr;
    }

    return result;
  };

  updateWithCollectsAndFallouts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadPlacementsSuccess),
      switchMap((action) => {
        const collectIds = action.placements
          .map((it) => it.placement_collect_ids)
          .filter((it) => !!it)
          .map((it) => it!);
        const falloutIds = action.placements
          .map((it) => it.placement_fallout_id)
          .filter((it) => !!it)
          .map((it) => it!);

        return merge(
          this.placementService.getPlacementCollects(flatten(collectIds)).pipe(
            map((response) =>
              fromActions.setCollects({
                collects: this.arrayToDictionary(response.data),
              }),
            ),
          ),
          this.placementService.getPlacementFallouts(flatten(falloutIds)).pipe(
            map((response) =>
              fromActions.setFallouts({
                fallouts: this.arrayToDictionary(response.data),
              }),
            ),
          ),
        );
      }),
    );
  });

  getPlacementHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPlacementHistory),
      switchMap(({ placementId, moneyDownSearchId }) => {
        const paymentsHistory$ = moneyDownSearchId
          ? this.placementService.getMoneyDownPaymentHistory(moneyDownSearchId)
          : of(null);
        return zip([
          of(placementId),
          this.placementService.getPlacementHistory(placementId),
          paymentsHistory$,
          this.placementService.getAllPlacementCollects(placementId),
        ]);
      }),
      map(([placementId, jobHistory, paymentHistory, placementCollects]) => {
        return fromActions.setPlacementHistory({
          placementId,
          placementHistory: {
            jobHistory: jobHistory.data,
            paymentHistory: paymentHistory?.data ?? [],
            collects: placementCollects.data,
          },
        });
      }),
    );
  });

  deletePlacementCollect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deletePlacementCollect),
      map((action) => action),
      switchMap(({ placementCollectId, placementId }) => {
        return this.placementService
          .deletePlacementCollect(placementCollectId)
          .pipe(
            map(() =>
              fromActions.deletePlacementCollectSuccess({
                placementCollectId,
                placementId,
              }),
            ),
            catchError((error) =>
              of(
                fromActions.deletePlacementCollectFailure({
                  error: error,
                }),
              ),
            ),
          );
      }),
    );
  });
}
