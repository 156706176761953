import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromGeneral from './general.reducer';
import * as fromMetadata from './metadata.reducer';
import * as fromMoneyDownSearch from './money-down-search.reducer';
import * as fromPlacement from './placement.reducer';
import * as fromOtherFee from './other-fee.reducer';

export const statsFeatureKey = 'stats';

export interface FeatureState {
    [fromGeneral.generalsFeatureKey]: fromGeneral.State;
    [fromPlacement.placementsFeatureKey]: fromPlacement.State;
    [fromMetadata.metadataFeatureKey]: fromMetadata.State;
    [fromMoneyDownSearch.moneyDownSearchFeatureKey]: fromMoneyDownSearch.State;
    [fromOtherFee.otherFeeFeatureKey]: fromOtherFee.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    [fromGeneral.generalsFeatureKey]: fromGeneral.reducer,
    [fromPlacement.placementsFeatureKey]: fromPlacement.reducer,
    [fromMetadata.metadataFeatureKey]: fromMetadata.reducer,
    [fromMoneyDownSearch.moneyDownSearchFeatureKey]:
        fromMoneyDownSearch.reducer,
    [fromOtherFee.otherFeeFeatureKey]: fromOtherFee.reducer,
};

export const getFeatureState =
    createFeatureSelector<FeatureState>(statsFeatureKey);
