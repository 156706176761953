import { createAction, props } from '@ngrx/store';
import {
  MoneyDownPayment,
  MoneyDownSearch,
  MoneyDownSearchDetails,
  MoneyDownSearchFilters,
} from '../../../modules/stats/models/money-down-search.model';
import {
  CompleteMoneyDownSearchBody,
  CreateMoneyDownSearchBody,
  UpdateMoneyDownSearchBody,
} from '../../../shared/services/money-down.service';

/**
 *
 * Load moneyDownSearches */
export const loadMoneyDownSearches = createAction(
  '[Stats] Load MoneyDownSearches',
  props<{ filters: MoneyDownSearchFilters }>(),
);

export const loadMoneyDownSearchesSuccess = createAction(
  '[Stats] Load MoneyDownSearches Success',
  props<{
    moneyDownSearches: MoneyDownSearch[];
  }>(),
);

export const loadMoneyDownSearchesFailure = createAction(
  '[Stats] Load MoneyDownSearches Failure',
  props<{ error: any }>(),
);

/**
 *
 * Add moneyDownSearch */
export const addMoneyDownSearch = createAction(
  '[Stats] Add MoneyDownSearch',
  props<{ data: CreateMoneyDownSearchBody }>(),
);

export const addMoneyDownSearchSuccess = createAction(
  '[Stats] Add MoneyDownSearch Success',
  props<{ data: MoneyDownSearch }>(),
);

export const addMoneyDownSearchFailure = createAction(
  '[Stats] Add MoneyDownSearch Failure',
  props<{ error: any }>(),
);

/**
 *
 * Upsert moneyDownSearch */
export const upsertMoneyDownSearch = createAction(
  '[Stats] Upsert MoneyDownSearch',
  props<{ moneyDownSearch: UpdateMoneyDownSearchBody; canEdit: boolean }>(),
);

export const upsertMoneyDownSearchSuccess = createAction(
  '[Stats] Upsert MoneyDownSearch Success',
  props<{ moneyDownSearch: MoneyDownSearch }>(),
);

export const upsertMoneyDownSearchFailure = createAction(
  '[Stats] Upsert MoneyDownSearch Failure',
  props<{ error: any }>(),
);

/**
 *
 * Delete moneyDownSearch */
export const deleteMoneyDownSearch = createAction(
  '[Stats] Delete MoneyDownSearch',
  props<{ id: number }>(),
);

export const deleteMoneyDownSearchSuccess = createAction(
  '[Stats] Delete MoneyDownSearch Success',
  props<{ id: number }>(),
);

export const deleteMoneyDownSearchFailure = createAction(
  '[Stats] Delete MoneyDownSearch Failure',
  props<{ error: any }>(),
);

// Select MoneyDownSearch
export const selectMoneyDownSearch = createAction(
  '[Stats] Select MoneyDownSearch',
  props<{ id?: number }>(),
);

export const setMoneyDownSearchDetails = createAction(
  '[Stats] Set MoneyDownSearch Details',
  props<{ moneyDownSearchDetails?: MoneyDownSearchDetails }>(),
);

/** Money Down Search Payments */

export const setMoneyDownSearchPayments = createAction(
  '[Stats] Set Money Down Payments',
  props<{ moneyDownPayments: MoneyDownPayment[] }>(),
);

export const completeMoneyDownSearch = createAction(
  '[Stats] Complete Money Down Search',
  props<{ body: CompleteMoneyDownSearchBody }>(),
);

export const completeMoneyDownSearchSuccess = createAction(
  '[Stats] Complete Money Down Search Success',
  props<{ moneyDownSearchId: number }>(),
);
