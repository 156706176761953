import { createSelector } from '@ngrx/store';
import { PlacementActivityDetails } from '../../../shared/models/placement.model';

import * as fromFeature from '../reducers';
import * as fromActivity from '../reducers/activity.reducer';

export const getActivityState = createSelector(
    fromFeature.getFeatureState,
    (state: fromFeature.FeatureState) => state.activities,
);

export const getActivityEntities = createSelector(
    getActivityState,
    fromActivity.selectEntities,
);

export const getAllActivities = createSelector(
    getActivityEntities,
    (entities) => {
        return Object.values(entities) as PlacementActivityDetails[];
    },
);
