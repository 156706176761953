export interface User {
    accountId: number;
    sub: string;
    exp: number;
    iat: number;
}

export enum AppUserType {
    ENTERPRISE = 1,
    NATIONAL = 2,
    ADMIN = 3,
    OWNER = 4,
    RECRUITER = 5,
    WEBMANAGER = 6,
    FRSTAFF = 7,
}

export interface UserDetails {
    id: number;
    first_name: string;
    last_name: string;
    user_name: string;
    frozen_date: string;
    app_user_type_id: AppUserType;
    office_id: number;
    region_id: number;
    title: string;
    is_owner: boolean;
}

export function getUserAppType(appTypeId: AppUserType) {
    switch (appTypeId) {
        case AppUserType.ENTERPRISE:
            return 'Enterprise';
        case AppUserType.ADMIN:
            return 'Admin';
        case AppUserType.OWNER:
            return 'Owner';
        case AppUserType.RECRUITER:
            return 'Recruiter';
        case AppUserType.WEBMANAGER:
            return 'Web Manager';
        case AppUserType.FRSTAFF:
            return 'FR Staff';
        case AppUserType.NATIONAL:
            return 'National';
        default:
            return null;
    }
}
