import {
    Component,
    AfterViewInit,
    OnDestroy,
    ChangeDetectionStrategy,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-loading',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements AfterViewInit, OnDestroy {
    loadingSubscription!: Subscription;
    isLoading: boolean = false;

    constructor(private loadingService: LoadingService) {}

    ngAfterViewInit(): void {
        this.loadingSubscription = this.loadingService.loading$
            .pipe()
            .subscribe((status: boolean) => {
                this.isLoading = status;
            });
    }

    ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
    }
}
