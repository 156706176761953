import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { General } from '../../../modules/stats/models/general.model';
import * as GeneralActions from '../actions/general.actions';

export const generalsFeatureKey = 'generals';

export interface State extends EntityState<General> {
    loaded: boolean;
    error: string;
    editableIds: Set<number>;
}

export const adapter: EntityAdapter<General> = createEntityAdapter<General>({
    selectId: (it) => it.week_number,
});

export const initialState: State = adapter.getInitialState({
    loaded: false,
    error: '',
    editableIds: new Set(),
});

export const reducer = createReducer(
    initialState,
    on(GeneralActions.loadGeneralsSuccess, (state, action) =>
        adapter.setAll(action.generals, state),
    ),
    on(GeneralActions.upsertGeneralSuccess, (state, action) => {
        const editableIds = state.editableIds;
        editableIds.delete(action.general.id);
        return adapter.updateOne(
            { changes: action.general, id: action.general.id },
            { ...state, editableIds },
        );
    }),
    on(GeneralActions.deleteGeneralSuccess, (state, action) =>
        adapter.removeOne(action.id, state),
    ),
    /** Error handling */
    on(
        GeneralActions.loadGeneralsFailure,
        GeneralActions.upsertGeneralFailure,
        GeneralActions.deleteGeneralFailure,
        (state, action) => {
            const { status, statusText } = action.error;

            return { ...state, error: `${status}: ${statusText}` };
        },
    ),
    on(GeneralActions.editGeneralObject, (state, action) => {
        return { ...state, editables: state.editableIds.add(action.id) };
    }),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
    adapter.getSelectors();
