import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, of, mergeMap } from 'rxjs';

import * as fromActions from '../actions/general.actions';
import { GeneralService } from '../../../modules/stats/services/general.service';
import { filter } from 'lodash';

@Injectable()
export class GeneralEffects {
    constructor(
        private actions$: Actions,
        private generalService: GeneralService,
    ) {}

    loadGenerals$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.loadGenerals),
            map((action) => action.filters),
            switchMap((filters) => {
                return this.generalService.getGenerals(filters).pipe(
                    switchMap((response) => {
                        if (response && response.length > 0) {
                            return of(
                                fromActions.loadGeneralsSuccess({
                                    generals: response,
                                }),
                            );
                        } else {
                            const payload = {
                                year: filters.year,
                                month: filters.month,
                                report_owner: filters.recruiter_id,
                            };

                            return this.generalService
                                .createGeneral(payload)
                                .pipe(
                                    map((response) =>
                                        fromActions.loadGeneralsSuccess({
                                            generals: response,
                                        }),
                                    ),
                                    catchError((error) =>
                                        of(
                                            fromActions.loadGeneralsFailure({
                                                error: error,
                                            }),
                                        ),
                                    ),
                                );
                        }
                    }),

                    catchError((error) => {
                        return of(
                            fromActions.loadGeneralsFailure({
                                error: error,
                            }),
                        );
                    }),
                );
            }),
        );
    });

    updateGeneral$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.upsertGeneral),
            map((action) => action.general),
            mergeMap((item) => {
                return this.generalService.updateGeneral(item).pipe(
                    map((response) =>
                        fromActions.upsertGeneralSuccess({
                            general: response,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActions.upsertGeneralFailure({
                                error: error,
                            }),
                        ),
                    ),
                );
            }),
        );
    });

    deleteGeneral$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.deleteGeneral),
            map((action) => action.id),
            switchMap((item) => {
                return this.generalService.deleteGeneral(item).pipe(
                    map(() =>
                        fromActions.deleteGeneralSuccess({
                            id: item,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActions.deleteGeneralFailure({
                                error: error,
                            }),
                        ),
                    ),
                );
            }),
        );
    });
}
