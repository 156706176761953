import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, of, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import {
    General,
    GeneralCreate,
    GeneralFilters,
} from '../models/general.model';
import { Response } from 'src/app/shared/models/response.model';

@Injectable({
    providedIn: 'root',
})
export class GeneralService {
    constructor(private http: HttpClient) {}

    getGenerals(filters: GeneralFilters): Observable<General[]> {
        let params = new HttpParams({ fromObject: filters as any });

        return this.http
            .get<Response<General[]>>(
                `${environment.baseUrl}/general-statistics`,
                {
                    params: params,
                },
            )
            .pipe(
                map((response) => {
                    const { data } = response;
                    return data;
                }),
                catchError(() => of([])),
            );
    }

    createGeneral(payload: GeneralCreate): Observable<General[]> {
        if (Number(payload.report_owner ?? 0) === 0) {
            return of([]);
        }

        return this.http
            .post<Response<General[]>>(
                `${environment.baseUrl}/general-statistics`,
                payload,
            )
            .pipe(
                map((response) => {
                    const { data } = response;

                    return data;
                }),
                catchError(() => of([])),
            );
    }

    updateGeneral(payload: General): Observable<General> {
        return this.http
            .put<Response<General>>(
                `${environment.baseUrl}/general-statistics`,
                payload,
            )
            .pipe(
                map((response) => {
                    const { data } = response;

                    return data;
                }),
            );
    }

    deleteGeneral(id: number): Observable<General> {
        let params = new HttpParams();
        params = params.append('id', id);

        return this.http.delete<General>(
            `${environment.baseUrl}/general-statistics`,
            {
                params: params,
            },
        );
    }
}
