import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { LoadingService } from '../services/loading.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
    constructor(
        private loader: LoadingService,
        private authService: AuthService,
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        this.loader.startLoading();

        request = request.clone({
            withCredentials: true,
        });

        if (request.url.includes('login') || request.url.includes('reset')) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((error: any) => {
                let errorMessage = '';
                errorMessage = error;

                // Error 401 and user is logged in
                if (error.status === 401 && this.authService.isLoggedin()) {
                    this.authService.handleLogout('bearer');
                }

                return throwError(() => errorMessage);
            }),
            finalize(() => {
                this.loader.stopLoading();
            }),
        );
    }
}
