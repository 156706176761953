import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OtherFee } from '../../../modules/stats/models/other-fee.model';
import * as OtherFeeActions from '../actions/other-fee.actions';

export const otherFeeFeatureKey = 'otherFee';

export interface State extends EntityState<OtherFee> {
    loaded: boolean;
    error?: string;
    message?: string;
}

export const adapter: EntityAdapter<OtherFee> = createEntityAdapter<OtherFee>();

export const initialState: State = adapter.getInitialState({
    loaded: false,
});

/**
 * Rehydrate by removing one time properties
 * @param state Previous State
 * @returns New State
 */
export const rehydrate = (state: State): State => {
    return {
        ...state,
        message: undefined,
    };
};

export const reducer = createReducer(
    initialState,
    on(OtherFeeActions.loadOtherFeesSuccess, (state, action) =>
        adapter.setAll(action.otherFees, {
            ...rehydrate(state),
            loaded: true,
        }),
    ),
    on(OtherFeeActions.upsertOtherFeeSuccess, (state, action) =>
        adapter.upsertOne(action.otherFee, rehydrate(state)),
    ),
    on(OtherFeeActions.deleteOtherFeeSuccess, (state, action) =>
        adapter.removeOne(action.id, rehydrate(state)),
    ),
    /** Error handling */
    on(
        OtherFeeActions.loadOtherFeesFailure,
        OtherFeeActions.addOtherFeeFailure,
        OtherFeeActions.upsertOtherFeeFailure,
        OtherFeeActions.deleteOtherFeeFailure,
        (state, action) => {
            const { status, statusText } = action.error;

            return { ...rehydrate(state), error: `${status}: ${statusText}` };
        },
    ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
    adapter.getSelectors();
