import { Placement } from 'src/app/shared/models/placement.model';
import { AppUserType } from 'src/app/shared/models/user.model';
import { MoneyDownSearch } from '../../stats/models/money-down-search.model';

export enum ReportType {
  TopPlacementReport = 'Placement Report',
  TopMoneyDownSearchReport = 'Money Down Search Report',
  TopRecruiterReport = 'Top Recruiter Report',
  TopIndividualReport = 'Top Individual Report',
  TopOwnerReport = 'Top Owner Report',
  TopRecruiterExchangeReport = 'Top Recruiter Exchange Report',
  TopOwnerExchangeReport = 'Top Owner Exchange Report',
  TopOfficeReport = 'Top Office Report',
  TopOfficeExchangeReport = 'Top Office Exchange Report',
  SystemWideReport = 'System Wide Report',
  OfficeReport = 'Office Report',
  AgingReport = 'Aging Report',
  TopPercentileByJobFunction = 'Top Percentile By Job Function',
  TopPercentileByIndustry = 'Top Percentile By Industry',
  CollectionsReport = 'Collections Report',
  OutstandingCollectionsReport = 'Outstanding Collections Report',
  FalloutReport = 'Fallout Report',
}

export enum SystemWideReportType {
  Yearly = 'Yearly',
  AllItems = 'All Items',
}

export enum YTDReportType {
  Placement = 'placement',
  MoneyDown = 'money_down',
  Other = 'other',
}

export enum CollectionsReportType {
  Placement = 'collection_placement',
  MoneyDown = 'collection_money_down',
  Fallouts = 'collection_fallouts',
  Other = 'other_fee',
}

export enum OutstandingCollectionsReportType {
  Placement = 'outstanding_placement',
}

export type AllReportsFormatType =
  | Partial<ReportType>
  | Partial<SystemWideReport>
  | Partial<YTDReportType>;

export const getReportTypesForAppUserType = (
  appUserType: AppUserType,
): ReportType[] => {
  let reportTypes: ReportType[] = [
    ReportType.TopPlacementReport,
    ReportType.TopMoneyDownSearchReport,
    ReportType.AgingReport,
  ];

  if (appUserType <= AppUserType.NATIONAL) {
    return reportTypes.concat([
      ReportType.TopRecruiterReport,
      ReportType.TopIndividualReport,
      ReportType.TopOwnerReport,
      ReportType.TopOfficeReport,
      ReportType.TopRecruiterExchangeReport,
      ReportType.TopOwnerExchangeReport,
      ReportType.TopOfficeExchangeReport,
      ReportType.TopPercentileByJobFunction,
      ReportType.TopPercentileByIndustry,
      ReportType.CollectionsReport,
      ReportType.OutstandingCollectionsReport,
      ReportType.SystemWideReport,
      ReportType.FalloutReport,
    ]);
  }

  return reportTypes;
};

export interface ReportFilters {
  start_date: string;
  end_date: string;
  office_id?: string;
  recruiter_id?: string;
  exclude_frozen?: boolean;
  is_shown?: boolean;
}

export interface OfficeReportFilters {
  office_id: string;
  consultant_id: string;
  year: string;
  exclude_frozen: boolean;
  is_shown?: boolean;
}

export interface TopRecruiterItem {
  office_name: string;
  full_name: string;
  rank: number;
  production: number;
}

export interface TopOfficeItem {
  office_name: string;
  office_owner: string;
  rank: number;
  production: number;
}

export interface TopExchangeItem {
  full_name: string;
  office_name: string;
  exchange: number;
  rank: number;
  exchange_num: number;
}

export interface TopOfficeExchangeItem {
  office_name: string;
  office_owner: string;
  exchange: number;
  rank: number;
  exchange_num: number;
}

export interface AgingReportItem {
  id: number;
  office_name: string;
  fee_amount: number;
  fee_collected: number;
  full_name: string;
  is_exchange: boolean;
  job_title: string;
  outstanding_fee: number;
  placement_owner: number;
  report_date: string;
  start_date: string;
  less_60: number;
  between_60_90: number;
  more_90: number;
}

export interface OfficeReportItem {
  year: number;
  month: number;
  full_name: string;
  cpr: number;
  video_interview: number;
  premium_jobs: number;
  total_jobs: number;
  telephone_interview: number;
  first_level_interview: number;
  prod: number;
  total: number;
  placement_fee: number;
  placement_quantity: number;
  split_fee: number;
  split_quantity: number;
  exchange_fee: number;
  exchange_quantity: number;
  money_down_fee: number;
  money_down_quantity: number;
  other_fee: number;
  other_quantity: number;
  collect_fee: number;
  collect_quantity: number;
  fallout_fee: number;
  fallout_quantity: number;
}

export type UserOfficeReportResponseType = {
  name: string;
  data: OfficeReportItem[];
};

export type SystemWideReport = [
  OfficeReportItem[],
  OfficeReportItem[],
  Omit<OfficeReportItem, 'year' | 'month'>[],
];

export interface FalloutReport {
  office_name: string;
  full_name: string;
  job_title: string;
  inv_ref: string;
  start_date: string;
  placement_date: string;
  fallout_date: string;
  is_exchange: boolean;
  invoice_fee: number;
  fee_collected: number;
  fallout_fee: number;
  profit: number;
}

export interface TopPercentileByJobFunctionReport {
  job_function: string;
  pl: number;
  total_fee: number;
  avg_fee: number;
  exchange_qty: number;
  exchange_fee: number;
}

export interface TopPercentileByIndustry {
  industry: string;
  pl: number;
  total_fee: number;
  avg_fee: number;
  exchange_qty: number;
  exchange_fee: number;
}

export interface YTDCollectionReport {
  office_name: string;
  placements?: {
    full_name: string;
    job_title: string;
    start_date: string;
    placement_date: string;
    invoice_fee: number;
    fee_collection: number;
    outstanding: number;
  }[];
  money_down?: {
    full_name: string;
    job_title: string;
    total_fee_received: number;
    created_datetime: string;
  }[];
  other_fee?: {
    full_name: string;
    note: string;
    fee_amount: number;
    created_datetime: string;
  }[];
}

export interface CollectionsReport {
  office_name: string;
  placements: {
    full_name: string;
    inv_ref: string;
    start_date: string;
    placement_date: string;
    invoice_fee: number;
    fee_collected: number;
    is_exchange: boolean;
    job_title: string;
  }[];
  fallouts: {
    full_name: string;
    inv_ref: string;
    start_date: string;
    placement_date: string;
    invoice_fee: number;
    fee_collected: number;
    is_exchange: boolean;
    job_title: string;
  }[];
  money_down: {
    full_name: string;
    job_title: string;
    fee_collected: number;
    created_datetime: string | null;
    placement_date: string;
    is_completed: boolean;
  }[];
  other_fee: {
    full_name: string;
    note: string;
    fee_amount: number;
    created_datetime: string | null;
    placement_date: string;
  }[];
  grand_total?: number;

  total_placement_invoice_fee: number;
  total_placement_fee_collected: number;
  total_money_down_fee_collected: number;
  total_other_fee_amount: number;
  total_fallout_invoice_fee: number;
  total_fallout_fee_collected: number;
  total_collections_received: number;
}

export interface OutstandingCollectionsReport {
  office_name: string;
  placements: {
    full_name: string;
    job_title: string;
    start_date: string;
    placement_date: string;
    invoice_fee: number;
    fee_collected: number;
    outstanding: number;
    is_exchange: boolean;
  }[];
  total_fee_collected: number;
  total_invoice_fee: number;
  total_outstanding: number;
}

export enum ReportFieldType {
  Date = 'date',
  DateISO = 'date-iso',
  Currency = 'currency',
  Number = 'number',
  JobCategoryId = 'job-category-id',
  JobSubCategoryId = 'job-sub-category-id',
  IndustryId = 'industry-id',
  CompanySizeId = 'company-size-id',
  AppUserId = 'app-user-id',
  Boolean = 'boolean',
  Month = 'month',
  PlacementType = 'placement-type', // based on is_exchange provides exchange or full
}

export const numericalFields = [
  ReportFieldType.Currency,
  ReportFieldType.Number,
];

export interface ReportFieldDefinition {
  title?: string;
  fieldType?: ReportFieldType;
  isHidden?: boolean;
  isHiddenByFilter?: boolean;
  colSize?: number;
}
export type ReportFormatDefinition<ResultModel> = Partial<
  Record<keyof ResultModel, ReportFieldDefinition | null>
>;

export type ReportSource =
  | ((Placement | MoneyDownSearch) & { office_name: string })
  | TopPercentileByJobFunctionReport;
type ReportFormat = Record<ReportType, ReportFormatDefinition<ReportSource>>;
export const ReportFormats: Partial<ReportFormat> = {
  [ReportType.OfficeReport]: {
    month: {
      fieldType: ReportFieldType.Month,
    },
    total: {
      title: 'PL',
      fieldType: ReportFieldType.Number,
    },
    prod: {
      title: '$ Prod',
      fieldType: ReportFieldType.Currency,
    },
    cpr: {
      title: 'CPR',
      fieldType: ReportFieldType.Number,
    },
    telephone_interview: {
      title: 'TI',
      fieldType: ReportFieldType.Number,
      isHiddenByFilter: true,
    },
    video_interview: {
      title: '1st Interview',
      fieldType: ReportFieldType.Number,
    },
    first_level_interview: {
      title: '2nd Interview',
      fieldType: ReportFieldType.Number,
    },
    total_jobs: { title: 'TJobs', fieldType: ReportFieldType.Number },
    premium_jobs: { title: 'PJobs', fieldType: ReportFieldType.Number },
    exchange_quantity: {
      title: 'Exch.',
      fieldType: ReportFieldType.Number,
    },
    exchange_fee: {
      title: '$ Exch.',
      fieldType: ReportFieldType.Currency,
    },
    split_quantity: {
      title: 'Split',
      fieldType: ReportFieldType.Number,
    },
    split_fee: {
      title: '$ Split',
      fieldType: ReportFieldType.Currency,
    },
    money_down_quantity: {
      title: 'MD',
      fieldType: ReportFieldType.Number,
    },
    money_down_fee: {
      title: '$ MD',
      fieldType: ReportFieldType.Currency,
    },
    collect_fee: {
      title: '$ Collection',
      fieldType: ReportFieldType.Currency,
    },
  },
  [ReportType.TopPlacementReport]: {
    office_name: { title: 'Office' },
    full_name: {
      title: 'Principal / Consultant',
    },
    job_title: null,
    job_function: {
      title: 'Category',
    },
    job_sub_category: {
      title: 'Sub Category',
    },
    industry: {
      title: 'Industry',
    },
    company_size: {
      title: 'Company Size',
    },
    hiring_manager: {
      fieldType: ReportFieldType.Boolean,
    },
    human_resource: {
      fieldType: ReportFieldType.Boolean,
    },
    associate_assist: {
      fieldType: ReportFieldType.Boolean,
    },
    is_premium: {
      title: 'Premium',
      fieldType: ReportFieldType.Boolean,
    },
    is_relocated: {
      title: 'Relocated',
      fieldType: ReportFieldType.Boolean,
    },
    start_date: {
      fieldType: ReportFieldType.Date,
    },
    placement_date: {
      title: 'Report Date',
      fieldType: ReportFieldType.Date,
    },
    is_exchange: {
      title: 'Type',
      fieldType: ReportFieldType.PlacementType,
    },
    salary: {
      fieldType: ReportFieldType.Currency,
    },
    fee_amount: {
      title: 'Fee Amount',
      fieldType: ReportFieldType.Currency,
    },
    id: {
      isHidden: true,
    },
  } as ReportFormatDefinition<Placement>,
  [ReportType.TopMoneyDownSearchReport]: {
    office_name: {
      title: 'Office',
    },
    full_name: {
      title: 'Principal/Consultant',
    },
    job_title: null,
    placement_date: {
      title: 'Report Date',
      fieldType: ReportFieldType.Date,
    },
    total_fee_recieved: {
      title: '$ Received',
      fieldType: ReportFieldType.Currency,
    },
    is_completed: {
      title: 'Completed',
      fieldType: ReportFieldType.Boolean,
    },
    id: {
      isHidden: true,
    },
  } as ReportFormatDefinition<MoneyDownSearch>,
  [ReportType.AgingReport]: {
    office_name: {
      title: 'Office',
    },
    full_name: {
      title: 'Principal/Consultant',
    },
    job_title: null,
    start_date: {
      fieldType: ReportFieldType.Date,
    },
    placement_date: {
      title: 'Report Date',
      fieldType: ReportFieldType.Date,
    },
    fee_amount: {
      fieldType: ReportFieldType.Currency,
    },
    fee_collected: {
      fieldType: ReportFieldType.Currency,
    },
    outstanding_fee: {
      title: 'Outstanding',
      fieldType: ReportFieldType.Currency,
    },
    less_60: {
      title: '0-60 days',
      fieldType: ReportFieldType.Currency,
    },
    between_60_90: {
      title: '60-90 days',
      fieldType: ReportFieldType.Currency,
    },
    more_90: {
      title: '90+ days',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<AgingReportItem>,
  [ReportType.TopRecruiterReport]: {
    rank: null,
    full_name: {
      title: 'Consultant',
    },
    office_name: {
      title: 'Office',
    },
    production: {
      title: 'Production',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopRecruiterItem>,
  [ReportType.TopIndividualReport]: {
    rank: null,
    full_name: {
      title: 'Principal/Consultant',
    },
    office_name: {
      title: 'Office',
    },
    production: {
      title: '$ Production',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopRecruiterItem>,
  [ReportType.TopOwnerReport]: {
    rank: null,
    full_name: {
      title: 'Principal',
    },
    office_name: { title: 'Office' },
    production: {
      title: '$ Production',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopRecruiterItem>,
  [ReportType.TopOfficeReport]: {
    rank: null,
    office_name: { title: 'Office' },
    office_owner: {
      title: 'Principal',
    },
    production: {
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopOfficeItem>,
  [ReportType.TopRecruiterExchangeReport]: {
    rank: null,
    full_name: {
      title: 'Consultant',
    },
    office_name: {
      title: 'Office',
    },
    exchange_num: {
      title: '# Exchange',
      fieldType: ReportFieldType.Number,
    },
    exchange: {
      title: '$ Exchange',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopExchangeItem>,
  [ReportType.TopOwnerExchangeReport]: {
    rank: null,
    full_name: {
      title: 'Consultant',
    },
    office_name: {
      title: 'Office',
    },
    exchange_num: {
      title: '# Exchange',
      fieldType: ReportFieldType.Number,
    },
    exchange: {
      title: '$ Exchange',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopExchangeItem>,
  [ReportType.TopOfficeExchangeReport]: {
    rank: null,
    office_name: {
      title: 'Office',
    },
    office_owner: {
      title: 'Principal',
    },
    exchange_num: {
      title: '# Exchange',
      fieldType: ReportFieldType.Number,
    },
    exchange: {
      title: '$ Exchange',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopOfficeExchangeItem>,
  [ReportType.TopPercentileByJobFunction]: {
    job_function: null,
    pl: {
      title: 'PL',
      fieldType: ReportFieldType.Number,
    },
    total_fee: {
      title: '$ Total Fee',
      fieldType: ReportFieldType.Currency,
    },
    avg_fee: {
      title: '$ Avg Fee',
      fieldType: ReportFieldType.Currency,
    },
    avg_salary: {
      title: '$ Avg Salary',
      fieldType: ReportFieldType.Currency,
    },
    exchange_qty: {
      title: '# Exchange',
      fieldType: ReportFieldType.Number,
    },
    exchange_fee: {
      title: '$ Exchange',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopPercentileByJobFunctionReport>,
  [ReportType.TopPercentileByIndustry]: {
    industry: null,
    pl: {
      title: 'PL',
      fieldType: ReportFieldType.Number,
    },
    total_fee: {
      title: '$ Total Fee',
      fieldType: ReportFieldType.Currency,
    },
    avg_fee: {
      title: '$ Avg Fee',
      fieldType: ReportFieldType.Currency,
    },
    avg_salary: {
      title: '$ Avg Salary',
      fieldType: ReportFieldType.Currency,
    },
    exchange_qty: {
      title: '# Exchange',
      fieldType: ReportFieldType.Number,
    },
    exchange_fee: {
      title: '$ Exchange',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<TopPercentileByIndustry>,
  [ReportType.FalloutReport]: {
    full_name: {
      title: 'Principal/Consultant',
    },
    job_title: {
      title: 'Job Title',
    },
    inv_ref: {
      title: 'Inv#/Ref/Cand.',
    },
    start_date: {
      fieldType: ReportFieldType.Date,
    },
    placement_date: {
      title: 'Report Date',
      fieldType: ReportFieldType.Date,
    },
    fallout_date: {
      title: 'Fallout Date',
      fieldType: ReportFieldType.Date,
    },
    is_exchange: {
      title: 'Type',
      fieldType: ReportFieldType.PlacementType,
    },
    invoice_fee: {
      title: '$ Invoice Amount',
      fieldType: ReportFieldType.Currency,
    },
    fee_collected: {
      title: '$ Collection Received',
      fieldType: ReportFieldType.Currency,
    },
    fallout_fee: {
      title: '$ Fallout',
      fieldType: ReportFieldType.Currency,
    },
    id: {
      isHidden: true,
    },
  } as ReportFormatDefinition<FalloutReport>,
};

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
export type SystemWideReportSource = ArrayElement<
  SystemWideReport['0'] | SystemWideReport['1'] | SystemWideReport['2']
>;
type SystemWideReportFormat = Record<
  SystemWideReportType,
  ReportFormatDefinition<SystemWideReportSource>
>;
export const SystemWideReportFormats: Partial<SystemWideReportFormat> = {
  [SystemWideReportType.Yearly]: {
    month: {
      fieldType: ReportFieldType.Month,
    },
    total: {
      title: 'PL',
      fieldType: ReportFieldType.Number,
    },
    prod: {
      title: '$ Prod',
      fieldType: ReportFieldType.Currency,
    },
    cpr: {
      title: 'CPR',
      fieldType: ReportFieldType.Number,
    },
    telephone_interview: {
      title: 'TI',
      fieldType: ReportFieldType.Number,
      isHiddenByFilter: true,
    },
    video_interview: {
      title: '1st Interview',
      fieldType: ReportFieldType.Number,
    },
    first_level_interview: {
      title: '2nd Interview',
      fieldType: ReportFieldType.Number,
    },
    total_jobs: {
      title: 'TJobs',
      fieldType: ReportFieldType.Number,
    },
    premium_jobs: {
      title: 'PJobs',
      fieldType: ReportFieldType.Number,
    },
    // placement_fee: {
    //     title: 'Money from placements',
    //     fieldType: ReportFieldType.Currency,
    // },
    exchange_quantity: {
      title: 'Exch.',
      fieldType: ReportFieldType.Number,
    },
    exchange_fee: {
      title: '$ Exch.',
      fieldType: ReportFieldType.Currency,
    },
    split_quantity: {
      title: 'Split',
      fieldType: ReportFieldType.Number,
    },
    split_fee: {
      title: '$ Split',
      fieldType: ReportFieldType.Currency,
    },
    money_down_quantity: {
      title: 'MD',
      fieldType: ReportFieldType.Number,
    },
    money_down_fee: {
      title: '$ MD',
      fieldType: ReportFieldType.Currency,
    },
    collect_fee: {
      title: '$ Collection',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<ArrayElement<SystemWideReport['0']>>,
  [SystemWideReportType.AllItems]: {
    office_name: { title: 'Office' },
    total: {
      title: 'PL Actual',
      fieldType: ReportFieldType.Number,
    },
    ytd_total: {
      title: 'PL YTD',
      fieldType: ReportFieldType.Number,
    },
    prod: {
      title: '$ Prod Actual',
      fieldType: ReportFieldType.Currency,
    },
    ytd_prod: {
      title: '$ Prod YTD',
      fieldType: ReportFieldType.Currency,
    },
    cpr: {
      title: 'CPR',
      fieldType: ReportFieldType.Number,
    },
    telephone_interview: {
      title: 'TI',
      fieldType: ReportFieldType.Number,
      isHiddenByFilter: true,
    },
    video_interview: {
      title: '1st Interview',
      fieldType: ReportFieldType.Number,
    },
    first_level_interview: {
      title: '2nd Interview',
      fieldType: ReportFieldType.Number,
    },
    total_jobs: {
      title: 'TJobs',
      fieldType: ReportFieldType.Number,
    },
    premium_jobs: {
      title: 'PJobs',
      fieldType: ReportFieldType.Number,
    },
    exchange_quantity: {
      title: 'Exch.',
      fieldType: ReportFieldType.Number,
    },
    exchange_fee: {
      title: '$ Exch.',
      fieldType: ReportFieldType.Currency,
    },
    split_quantity: {
      title: 'Split',
      fieldType: ReportFieldType.Number,
    },
    split_fee: {
      title: '$ Split',
      fieldType: ReportFieldType.Currency,
    },
    money_down_quantity: {
      title: 'MD',
      fieldType: ReportFieldType.Number,
    },
    money_down_fee: {
      title: '$ MD',
      fieldType: ReportFieldType.Currency,
    },
    collect_fee: {
      title: '$ Collection',
      fieldType: ReportFieldType.Currency,
    },
    office_id: {
      isHidden: true,
    },
  } as ReportFormatDefinition<ArrayElement<SystemWideReport['2']>>,
};

export type YTDReportSource = ArrayElement<
  | ArrayElement<YTDCollectionReport>['money_down']
  | ArrayElement<YTDCollectionReport>['placements']
  | ArrayElement<YTDCollectionReport>['other_fee']
>;
type YTDReportFormat = Record<
  YTDReportType,
  ReportFormatDefinition<YTDReportSource>
>;
export const YTDReportFormats: Partial<YTDReportFormat> = {
  [YTDReportType.Placement]: {
    full_name: {
      title: 'Principal/Consultant',
    },
    job_title: {
      title: 'Job Title',
    },
    start_date: {
      fieldType: ReportFieldType.Date,
    },
    placement_date: {
      title: 'Report Date',
      fieldType: ReportFieldType.Date,
    },
    invoice_fee: {
      title: '$ Invoice Amount',
      fieldType: ReportFieldType.Currency,
    },
    fee_collected: {
      title: '$ Collection Received',
      fieldType: ReportFieldType.Currency,
    },
    outstanding: {
      title: 'Amount Outstanding',
      fieldType: ReportFieldType.Currency,
    },
  } as ReportFormatDefinition<ArrayElement<YTDCollectionReport>['placements']>,
  [YTDReportType.MoneyDown]: {
    full_name: {
      title: 'Principal/Consultant',
    },
    job_title: null,
    total_fee_recieved: {
      fieldType: ReportFieldType.Currency,
    },
    placement_date: {
      fieldType: ReportFieldType.Date,
      title: 'Report Date',
    },
  },
  [YTDReportType.Other]: {
    full_name: {
      title: 'Principal/Consultant',
    },
    fee_amount: {
      fieldType: ReportFieldType.Currency,
    },
    note: null,
    created_datetime: {
      fieldType: ReportFieldType.DateISO,
      title: 'Report Date',
    },
  },
};

export type CollectionsReportSource = ArrayElement<
  | ArrayElement<CollectionsReport>['money_down']
  | ArrayElement<CollectionsReport>['placements']
  | ArrayElement<CollectionsReport>['other_fee']
  | ArrayElement<CollectionsReport>['fallouts']
>;
type CollectionsReportFormat = Record<
  CollectionsReportType,
  ReportFormatDefinition<CollectionsReportSource>
>;
export const CollectionsReportFormats: Partial<CollectionsReportFormat> = {
  [CollectionsReportType.Placement]: {
    placement_uid: {
      title: 'Placement UID',
      fieldType: ReportFieldType.Number,
      colSize: 2,
    },
    full_name: {
      title: 'Principal/ Consultant',
      colSize: 2,
    },
    job_title: {
      title: 'Job Title',
      colSize: 2,
    },
    inv_ref: {
      title: 'Inv#/Ref/Cand.',
      colSize: 2,
    },
    start_date: {
      fieldType: ReportFieldType.Date,
      colSize: 2,
    },
    placement_date: {
      title: 'Report Date',
      fieldType: ReportFieldType.Date,
      colSize: 2,
    },
    collection_date: {
      title: 'Collection Date',
      fieldType: ReportFieldType.Date,
      colSize: 2,
    },
    is_exchange: {
      title: 'Type',
      fieldType: ReportFieldType.PlacementType,
      colSize: 1,
    },
    invoice_fee: {
      title: '$ Invoice Amount',
      fieldType: ReportFieldType.Currency,
      colSize: 2,
    },
    fee_collected: {
      title: '$ Collection Received',
      fieldType: ReportFieldType.Currency,
      colSize: 2,
    },
    id: {
      isHidden: true,
    },
  } as ReportFormatDefinition<ArrayElement<CollectionsReport>['placements']>,
  [CollectionsReportType.MoneyDown]: {
    full_name: {
      title: 'Full Name',
      colSize: 2,
    },
    is_completed: {
      title: 'Completed',
      fieldType: ReportFieldType.Boolean,
      colSize: 2,
    },
    job_title: {
      title: 'Job Title',
      colSize: 6,
    },
    placement_date: {
      fieldType: ReportFieldType.Date,
      title: 'Report Date',
      colSize: 2,
    },
    collection_date: {
      title: 'Collection Date',
      fieldType: ReportFieldType.Date,
      colSize: 5,
    },
    fee_collected: {
      title: '$ Collection Received',
      fieldType: ReportFieldType.Currency,
      colSize: 2,
    },
    id: {
      isHidden: true,
    },
  },
  [CollectionsReportType.Other]: {
    full_name: {
      title: 'Full Name',
      colSize: 4,
    },
    note: {
      title: 'Note',
      colSize: 8,
    },
    placement_date: {
      fieldType: ReportFieldType.Date,
      title: 'Collection Date',
      colSize: 5,
    },
    fee_amount: {
      title: '$ Collection Received',
      fieldType: ReportFieldType.Currency,
      colSize: 2,
    },
  },
  [CollectionsReportType.Fallouts]: {
    placement_uid: {
      title: 'Placement UID',
      fieldType: ReportFieldType.Number,
      colSize: 2,
    },
    full_name: {
      title: 'Full Name',
      colSize: 2,
    },
    note: {
      title: 'Note',
      colSize: 2,
    },
    inv_ref: {
      title: 'Inv#/Ref/Cand.',
      colSize: 2,
    },
    start_date: {
      fieldType: ReportFieldType.Date,
      colSize: 2,
    },
    placement_date: {
      title: 'Report Date',
      fieldType: ReportFieldType.Date,
      colSize: 2,
    },
    collection_date: {
      title: 'Collection Date',
      fieldType: ReportFieldType.Date,
      colSize: 2,
    },
    is_exchange: {
      title: 'Type',
      fieldType: ReportFieldType.PlacementType,
      colSize: 1,
    },
    invoice_fee: {
      title: '$ Invoice Amount',
      fieldType: ReportFieldType.Currency,
      colSize: 2,
    },
    fee_collected: {
      title: '$ Collection Received',
      fieldType: ReportFieldType.Currency,
      colSize: 2,
    },
    id: {
      isHidden: true,
    },
  },
};

export type OutstandingCollectionsReportSource = ArrayElement<
  ArrayElement<CollectionsReport>['placements']
>;
type OutstandingCollectionsReportFormat = Record<
  OutstandingCollectionsReportType,
  ReportFormatDefinition<CollectionsReportSource>
>;
export const OutstandingCollectionsReportFormats: Partial<OutstandingCollectionsReportFormat> =
  {
    [OutstandingCollectionsReportType.Placement]: {
      placement_uid: {
        title: 'Placement UID',
        fieldType: ReportFieldType.Number,
      },
      full_name: {
        title: 'Principal / Consultant',
      },
      job_title: null,
      inv_ref: {
        title: 'Inv#/Ref/Cand.',
      },
      start_date: {
        fieldType: ReportFieldType.Date,
      },
      placement_date: {
        title: 'Report Date',
        fieldType: ReportFieldType.Date,
      },
      is_exchange: {
        title: 'Type',
        fieldType: ReportFieldType.PlacementType,
      },
      invoice_fee: {
        title: '$ Invoice Amount',
        fieldType: ReportFieldType.Currency,
      },
      fee_collected: {
        title: '$ Total Collected',
        fieldType: ReportFieldType.Currency,
      },
      outstanding: {
        title: '$ Amount Outstanding',
        fieldType: ReportFieldType.Currency,
      },
      id: {
        isHidden: true,
      },
    } as ReportFormatDefinition<
      ArrayElement<OutstandingCollectionsReport>['placements']
    >,
  };

export type AllReportFormat =
  | Partial<ReportFormat>
  | Partial<SystemWideReportFormat>
  | Partial<YTDReportFormat>
  | Partial<CollectionsReportFormat>
  | Partial<OutstandingCollectionsReportFormat>
  | ReportFormat[ReportType.OfficeReport];

export const DEFAULT_COL_SIZE = 80;

export const SYSTEMREPORT_TABLES_WITH_STICKY_HEADER = [
  ReportType.TopPlacementReport,
  ReportType.AgingReport,
  ReportType.FalloutReport,
];

export const SYSTEMREPORT_TABLES_WITH_SELECTABLE_ROW = [
  ReportType.TopPlacementReport,
];

export enum REPORT_SIDEBAR_MODAL {
  PLACEMENT_INFO,
  MONEY_DOWN_SEARCH_INFO,
}

const getColumnIndex = (
  report: ReportFormatDefinition<ReportSource> | undefined,
  key: string,
) => {
  return Object.keys(report || []).findIndex((keyName) => keyName === key);
};

export const SIDEBAR_INFO_MODAL_CONFIG = [
  /*--Placement sidebar--*/
  {
    reportName: ReportType.TopPlacementReport,
    idColumnIndex: getColumnIndex(
      ReportFormats[ReportType.TopPlacementReport],
      'id',
    ),
    modalType: REPORT_SIDEBAR_MODAL.PLACEMENT_INFO,
    clickableRow: getColumnIndex(
      ReportFormats[ReportType.TopPlacementReport],
      'job_title',
    ),
  },
  {
    reportName: OutstandingCollectionsReportType.Placement,
    idColumnIndex: getColumnIndex(
      OutstandingCollectionsReportFormats[
        OutstandingCollectionsReportType.Placement
      ],
      'id',
    ),
    modalType: REPORT_SIDEBAR_MODAL.PLACEMENT_INFO,
    clickableRow: getColumnIndex(
      OutstandingCollectionsReportFormats[
        OutstandingCollectionsReportType.Placement
      ],
      'job_title',
    ),
  },
  {
    reportName: CollectionsReportType.Placement,
    idColumnIndex: getColumnIndex(
      CollectionsReportFormats[CollectionsReportType.Placement],
      'id',
    ),
    modalType: REPORT_SIDEBAR_MODAL.PLACEMENT_INFO,
    clickableRow: getColumnIndex(
      CollectionsReportFormats[CollectionsReportType.Placement],
      'job_title',
    ),
  },
  {
    reportName: ReportType.FalloutReport,
    idColumnIndex: getColumnIndex(
      ReportFormats[ReportType.FalloutReport],
      'id',
    ),
    modalType: REPORT_SIDEBAR_MODAL.PLACEMENT_INFO,
    clickableRow: getColumnIndex(
      ReportFormats[ReportType.FalloutReport],
      'job_title',
    ),
  },

  /*--MDS sidebar--*/
  {
    reportName: ReportType.TopMoneyDownSearchReport,
    idColumnIndex: getColumnIndex(
      ReportFormats[ReportType.TopMoneyDownSearchReport],
      'id',
    ),
    modalType: REPORT_SIDEBAR_MODAL.MONEY_DOWN_SEARCH_INFO,
    clickableRow: getColumnIndex(
      ReportFormats[ReportType.TopMoneyDownSearchReport],
      'job_title',
    ),
  },
  {
    reportName: CollectionsReportType.MoneyDown,
    idColumnIndex: getColumnIndex(
      CollectionsReportFormats[CollectionsReportType.MoneyDown],
      'id',
    ),
    modalType: REPORT_SIDEBAR_MODAL.MONEY_DOWN_SEARCH_INFO,
    clickableRow: getColumnIndex(
      CollectionsReportFormats[CollectionsReportType.MoneyDown],
      'job_title',
    ),
  },
];
