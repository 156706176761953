import { formatCurrency } from '@angular/common';
import { isNil } from 'lodash';
import moment from 'moment';
import { ReportFieldType } from 'src/app/modules/reports/models/ReportType';
import { RootState } from 'src/app/store/stats';

export const reportsFormatters: Record<
  ReportFieldType,
  (
    value: string,
    metadata?: RootState['stats']['metadata'],
  ) => string | undefined
> = {
  [ReportFieldType.Date]: (value) =>
    value ? moment(value).format('MM/DD/YYYY') : '',
  [ReportFieldType.DateISO]: (value) =>
    value ? moment(value).format('hh:mm a MM/DD/YYYY') : '',
  [ReportFieldType.Currency]: (value) => {
    return formatCurrency(Number(value), 'en-US', '$', '1.0-2');
  },
  [ReportFieldType.Number]: (value) => Number(value).toLocaleString(),
  [ReportFieldType.JobCategoryId]: (value, metadata) => {
    return metadata?.jobFunctions
      ?.getDataOrEmpty()
      ?.find((it) => it.id === Number(value))?.name;
  },
  [ReportFieldType.JobSubCategoryId]: (value, metadata) => {
    return metadata?.jobFunctionSubCategories
      ?.getDataOrEmpty()
      ?.find((it) => it.id === Number(value))?.name;
  },
  [ReportFieldType.IndustryId]: (value, metadata) => {
    return metadata?.industries
      ?.getDataOrEmpty()
      ?.find((it) => it.id === Number(value))?.name;
  },
  [ReportFieldType.CompanySizeId]: (value, metadata) => {
    return metadata?.companySizes
      ?.getDataOrEmpty()
      ?.find((it) => it.id === Number(value))?.name;
  },
  [ReportFieldType.AppUserId]: (value, metadata) => {
    return metadata?.recruiters
      ?.getDataOrEmpty()
      ?.find((it) => it.id === Number(value))?.first_name;
  },
  [ReportFieldType.Boolean]: (value: string | boolean) =>
    isNil(value) ? '' : value === 'true' || value === true ? 'Yes' : 'No',
  [ReportFieldType.Month]: (month) => {
    return moment(month, 'MM').format('MMMM');
  },
  [ReportFieldType.PlacementType]: (value: any) =>
    isNil(value) ? '' : value === 'true' || value === true ? 'EXCH' : 'FULL',
};
