import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, merge, of, switchMap, take, withLatestFrom } from 'rxjs';
import RemoteResource from 'src/app/shared/models/resource.model';
import {
    JobFunction,
    JobFunctionSubcategory,
} from '../../../modules/stats/models/metadata.model';
import { MetadataService } from '../../../modules/stats/services/metadata.service';
import * as fromActions from '../actions/metadata.actions';
import uniqBy from 'lodash/uniqBy';
import { Store } from '@ngrx/store';
import { FeatureState } from '../reducers';
import { getMetadataState } from '../selectors';

@Injectable()
export class MetadataEffects {
    constructor(
        private store$: Store<FeatureState>,
        private action$: Actions,
        private metadataService: MetadataService,
    ) {}

    private getSubcategories(jobFunctionId: number) {
        return this.metadataService.getSubCategories(jobFunctionId).pipe(
            withLatestFrom(this.store$.select(getMetadataState)),
            map(([subCategories, metadataState]) => {
                const merged: JobFunctionSubcategory[] = uniqBy(
                    [
                        ...subCategories,
                        ...(metadataState.jobFunctionSubCategories.getDataOrEmpty() ??
                            []),
                    ],
                    'id',
                );
                return fromActions.setJobFunctionSubCategories({
                    result: RemoteResource.success(merged),
                });
            }),
        );
    }

    getMetadata$ = createEffect(() =>
        this.action$.pipe(
            ofType(fromActions.getMetadata),
            take(1),
            switchMap(() => {
                const jobFunctionsRequest = this.metadataService
                    .getJobFunctions()
                    .pipe(
                        switchMap((jobFunctions: JobFunction[]) => {
                            return merge(
                                of(
                                    fromActions.setJobFunctions({
                                        result: RemoteResource.success(
                                            jobFunctions,
                                        ),
                                    }),
                                ),
                                this.getSubcategories(jobFunctions[0].id),
                            );
                        }),
                    );

                const industriesRequest = this.metadataService
                    .getIndustries()
                    .pipe(
                        map((industries) =>
                            fromActions.setIndustries({
                                result: RemoteResource.success(industries),
                            }),
                        ),
                    );

                const companySizesRequest = this.metadataService
                    .getCompanySizes()
                    .pipe(
                        map((companySizes) =>
                            fromActions.setCompanySizes({
                                result: RemoteResource.success(companySizes),
                            }),
                        ),
                    );

                const recruitersRequest = this.metadataService
                    .getRecruiters()
                    .pipe(
                        map((recruiters) =>
                            fromActions.setRecruiters({
                                result: RemoteResource.success(
                                    recruiters
                                        .filter((it) => !it.frozen_date)
                                        .sort((a, b) => {
                                            const nameA =
                                                a.first_name +
                                                ' ' +
                                                a.last_name;
                                            const nameB =
                                                b.first_name +
                                                ' ' +
                                                b.last_name;
                                            if (nameA < nameB) {
                                                return -1;
                                            }
                                            if (nameA > nameB) {
                                                return 1;
                                            }
                                            return 0;
                                        }),
                                ),
                            }),
                        ),
                    );

                return merge(
                    jobFunctionsRequest,
                    industriesRequest,
                    companySizesRequest,
                    recruitersRequest,
                );
            }),
        ),
    );

    getJobFunctionSubcategory$ = createEffect(() =>
        this.action$.pipe(
            ofType(fromActions.getJobFunctionSubCategory),
            switchMap((action) => this.getSubcategories(action.jobFunctionId)),
        ),
    );

    getRecruiters$ = createEffect(() =>
        this.action$.pipe(
            ofType(fromActions.getRecruiters),
            switchMap(() => this.metadataService.getRecruiters()),
            map((result) =>
                fromActions.setRecruiters({
                    result: RemoteResource.success(result),
                }),
            ),
        ),
    );

    getOffices$ = createEffect(() =>
        this.action$.pipe(
            ofType(fromActions.getOffices),
            switchMap(() => this.metadataService.getOffices()),
            map((result) =>
                fromActions.setOffices({
                    result: RemoteResource.success(result),
                }),
            ),
        ),
    );

    getUserRoles$ = createEffect(() =>
        this.action$.pipe(
            ofType(fromActions.getUserRoles),
            switchMap((action) =>
                this.metadataService.getUserRoles(action.includeDisabled),
            ),
            map((result) =>
                fromActions.setUserRoles({
                    result: RemoteResource.success(result),
                }),
            ),
        ),
    );
}
