import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/authentication/guards/auth.guard';
import { DefaultGuard } from './modules/authentication/guards/default.guard';
import { LoadingComponent } from './shared/components/loading/loading.component';

const routes: Routes = [
    {
        path: '',
        title: 'loading',
        canActivate: [DefaultGuard],
        component: LoadingComponent,
    },
    {
        path: 'main',
        title: 'Main',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/landing/landing.module').then(
                (module) => module.LandingModule,
            ),
    },
    {
        path: 'login',
        title: 'Login',
        loadChildren: () =>
            import('./modules/authentication/authentication.module').then(
                (module) => module.AuthenticationModule,
            ),
    },
    {
        path: 'reset',
        title: 'Reset',
        loadChildren: () =>
            import('./modules/reset-password/reset-password.module').then(
                (module) => module.ResetPasswordModule,
            ),
    },
    {
        path: 'stats',
        title: 'Stats',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/stats/stats.module').then(
                (module) => module.StatsModule,
            ),
    },
    {
        path: 'activity',
        title: 'Activity',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/activity/activity.module').then(
                (module) => module.ActivityModule,
            ),
    },
    {
        path: 'reports',
        title: 'Reports',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/reports/reports.module').then(
                (module) => module.ReportsModule,
            ),
    },
    {
        path: 'user-management',
        title: 'User Management',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/user-management/user-management.module').then(
                (module) => module.UserManagementModule,
            ),
    },
    {
        path: 'office-management',
        title: 'Office Management',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/office-management/office-management.module').then(
                (module) => module.OfficeManagementModule,
            ),
    },
    {
        path: 'resources',
        title: 'Resources',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/resources/resources.module').then(
                (module) => module.ResourcesModule,
            ),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
