import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromActivity from './activity.reducer';

export const activityFeatureKey = 'activity';

export interface FeatureState {
    [fromActivity.activitiesFeatureKey]: fromActivity.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    [fromActivity.activitiesFeatureKey]: fromActivity.reducer,
};

export const getFeatureState =
    createFeatureSelector<FeatureState>(activityFeatureKey);
