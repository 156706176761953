import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Store } from '@ngrx/store';
import { identity } from 'lodash';
import moment from 'moment';
import {
  FeatureState,
  getActivityState,
  loadActivities,
} from 'src/app/store/activity';
import {
  AppUserType,
  getUserAppType,
  UserDetails,
} from '../../models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  @Input() isAuthenticated!: boolean;

  userDetails?: UserDetails | null;
  hasPendingActivities = false;

  constructor(
    private authService: AuthService,
    private store: Store<FeatureState>,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.authService.userDetails.subscribe((it) => {
      this.userDetails = it;
    });

    if (this.isAuthenticated) {
      this.store.dispatch(
        loadActivities({
          filters: {
            selectedMonth: moment().format('YYYY-MM'),
          },
        }),
      );
    }

    this.store.select(getActivityState).subscribe((it) => {
      this.hasPendingActivities = Object.entries(it.entities).length > 0;
    });
  }

  onLogout(): void {
    this.authService.handleLogout('bearer');
  }

  get fullName() {
    const result = [this.userDetails?.first_name, this.userDetails?.last_name]
      .filter(identity)
      .join(' ')
      .trim();
    if (result === '') {
      return 'Deactivated User';
    }
    return result;
  }

  get subtitle() {
    return [this.userType, this.userDetails?.user_name]
      .filter(identity)
      .join(' • ');
  }

  get userType() {
    if (!this.userDetails) {
      return null;
    }
    return getUserAppType(this.userDetails.app_user_type_id);
  }

  get showOfficeManagementScreens() {
    if (!this.userDetails) {
      return false;
    }

    if (this.userDetails.app_user_type_id <= AppUserType.NATIONAL) {
      return true;
    }

    return false;
  }

  get showUserManagementScreens() {
    if (!this.userDetails) {
      return false;
    }

    if (this.userDetails.app_user_type_id <= AppUserType.OWNER) {
      return true;
    }

    return false;
  }

  get showStatsLinks() {
    const firstPath = this.router.url?.split(/\/|\?/)?.[1] || '';
    return [
      'stats',
      'reports',
      'user-management',
      'office-management',
      'activity',
    ].includes(firstPath);
  }

  get showResourcesLinks() {
    const firstPath = this.router.url?.split(/\/|\?/)?.[1] || '';

    return firstPath === 'resources';
  }
}
