<app-loading></app-loading>

<ng-container *ngIf="showHeader(); else noAuthContainer">
  <app-header [isAuthenticated]="isAuthenticated"> </app-header>

  <div class="auth-container">
    <div class="100vh w-100">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>

<ng-template #noAuthContainer>
  <div class="not-auth-container">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<app-toast
  aria-live="polite"
  aria-atomic="true"
></app-toast>
